import { useEffect, useState, useContext, useRef } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useViewportSpy } from 'beautiful-react-hooks';
import qs from 'qs';

import {
	IonContent,
	IonPage,
	IonList,
	IonListHeader,
	IonToolbar,
	IonButtons,
	IonItem,
	IonLabel,
	IonHeader,
	IonModal,
	IonSearchbar,
	IonItemDivider,
	IonImg,
	IonCheckbox,
	NavContext,
	IonGrid,
	IonRow,
	IonCol,
	IonFab,
	IonFabButton,
	IonIcon,
	IonToast,
	useIonAlert,
} from '@ionic/react';

import fill from 'lodash/fill';
import findIndex from 'lodash/findIndex';

import { arrowUp, chevronUp, playCircle, time } from 'ionicons/icons';
import { useInterval } from 'beautiful-react-hooks';
import {
	useGlobalState,
	getIaModelOptions,
	getQuizModel,
	T,
	set_lungo_state,
	unpair,
	ia_check_reset,
	retrieve,
	ia_observe,
	ia_observe_v_state,
	hasNextQuiz,
} from '../state';

import times from 'lodash/times';

export default function Installation({ history }) {
	const contentRef = useRef(null);
	const timeoutRef = useRef(null);
	const mixerOptionRef = useRef(null);

	const { ux, ia, locale, instance } = useParams();

	const [local, setLocal] = useState(null);
	const [info, setInfo] = useState(null);
	const [modal, setModal] = useState(null);
	const [visible, setVisible] = useState(false);
	const [presentPlayStop, setPresentPlayStop] = useState(false);
	const [presentKaraokeControl, setPresentKaraokeControl] = useState(false);
	const [presentZoomControl, setPresentZoomControl] = useState(false);
	const [karaokeSelection, setKaraokeSelection] = useState({ bkg: -1, sng: -1 });
	const [searchTerm, setSearchTerm] = useState(null);
	const [filtered, setFiltered] = useState(null);
	const [presentAlert] = useIonAlert();
	const [_, setLoading] = useGlobalState('loading');
	const H = useHistory();
	const L = useLocation();

	const { search, pathname } = useLocation();
	// console.log(ux, ia, locale, instance, 'search', search, 'pathname', pathname);

	const { navigate } = useContext(NavContext);

	// auto-release to springboar
	useInterval(async () => {
		const reset = await ia_check_reset({ ia, instance });
		if (reset) {
			H.replace(`/${locale}/s`);
		}
	}, 30000);

	// timeout to handle big lists

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setVisible(true);
		}, 800);

		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
			unpair({ ia, instance });
		};
	}, []);

	useEffect(() => {
		if (ux && ia && instance && visible) {
			// isso tem que levar o state transiente do query da url
			const _local = getIaModelOptions({ ia, instance });

			setLocal(_local);

			if (contentRef.current) {
				contentRef.current.scrollToTop();
			}
			setLoading(false);
		}
	}, [ux, ia, instance, visible, pathname]);

	useEffect(() => {
		if (window.location.search) {
			const state = qs.parse(window.location.search.replace('?', ''));

			if (state.playing) {
				setPresentPlayStop(true);
			} else if (state.c && state.p) {
				const code = `${state.c}P${state.p}`;
				const _modal = getQuizModel({ code });

				setModal({ ..._modal });
				setPresentPlayStop(false);
			} else if (state.i && state.i !== 0) {
				setPresentZoomControl(true);
			}
		} else {
			setPresentPlayStop(false);
		}
	}, [window.location.search, presentKaraokeControl]);

	const handleOptionSelection = ({ id, label }) => {
		if (label.indexOf('/') !== -1) {
			const frg = label.split('/');

			setInfo({ title: frg[0], subtitle: frg[1] });
		} else {
			setInfo({ title: label, subtitle: null });
		}

		// handle option, via global state
		set_lungo_state({ i_state: `/m/${id}`, v_state: `/${pathname}/?playing=1`, ia, instance, observe: true });

		H.push({
			pathname,
			search: '?playing=1',
		});
	};

	const handleOptionStop = () => {
		set_lungo_state({ i_state: `/idle`, v_state: pathname, ia, instance });
	};

	const handleBack = () => {
		// unpair({ ia, instance });
		navigate(`/${locale}/s`, `back`);
	};

	const handleFabTop = () => {
		contentRef.current.scrollToTop();
	};

	// karaoke ------------

	const handleKaraokeBgSelection = (bkg) => {
		// change karaoke values
		setKaraokeSelection((sel) => ({ ...sel, bkg }));
		// muda o fundo no interativo
		const i_state = `/ready/${bkg}`;
		// update do interativo
		set_lungo_state({ i_state, ia, instance });
	};

	const handleKaraokeSngSelection = ({ sng, name }) => {
		// just save the song
		setKaraokeSelection((sel) => ({ ...sel, sng, sngname: name }));
	};

	const handleKaraokeStartTap = () => {
		// deconstruct the value
		const { bkg, sng, sngname } = karaokeSelection;
		// if we've got both
		if (bkg !== -1 && sng !== -1) {
			// confere ele da o push como esperado
			// history.push(`/pt/i/123/01/m`);
			const i_state = `/rec/${sng}/${bkg}`;
			const v_state = `/${locale}/i/${ia}/${instance}/k/s?rec=1`;

			const ref = `/${locale}/i/${ia}/${instance}/k`;

			set_lungo_state({ v_state, i_state, ia, instance, ref, observe: true });
			setLoading(true);

			setInfo({ title: T(`common.instructions.recording`), subtitle: sngname });
			setPresentKaraokeControl(true);

			timeoutRef.current = setTimeout(() => {
				const v_state = `/${locale}/i/${ia}/${instance}/k`;

				ia_observe_v_state({
					ia,
					instance,
					v_state,
					callback: () => {
						setPresentKaraokeControl(false);
					},
				});
			}, 10000);
		} else {
			// init definition
			let definition = null;

			// nofity the missing option
			if (bkg === -1) {
				// escolha um fundo
				definition = {
					header: T('common.warning.header'),
					message: T('common.warning.pick_karaoke_bkg'),
					buttons: ['ok'],
				};
			}
			// notify mission option
			if (sng === -1) {
				// escolha uma musica
				definition = {
					header: T('common.warning.header'),
					message: T('common.warning.pick_karaoke_sng'),
					buttons: ['ok'],
				};
			}
			// present alert as needed.
			if (definition) {
				presentAlert({ ...definition });
			}
		}
	};

	const handleKaraokeStopTap = () => {
		if (karaokeSelection.bkg && karaokeSelection.sng) {
			// start the record
			setPresentKaraokeControl(false);
			set_lungo_state({ i_state: `/idle`, v_state: pathname, ia, instance });
		}
	};

	// mixer ------------

	const handleMixerOptionSelection = ({ id, label }) => {
		const item = local.options.find((e, i) => {
			mixerOptionRef.current = i + 1;
			return e.key === id;
		});

		let _modal = null;

		if (item.instruments) {
			_modal = item.instruments.map((e) => ({ checked: true, label: e }));
		}

		// const state=retrieve()
		setInfo({ title: `${item.label}` });

		// channels
		const ch = fill(Array(item.instruments.length), 1);
		const i_state = `/c/${mixerOptionRef.current}/s?ch=${ch.join(',')}`;
		const v_state = `${pathname}/s?mixing=1`;

		set_lungo_state({ i_state, v_state, ia, instance, ref: pathname, observe: true });

		clearTimeout(timeoutRef.current);

		timeoutRef.current = setTimeout(() => {
			const v_state = `${pathname.replace(`/s?mixing=1`, ``)}`;

			ia_observe_v_state({
				ia,
				instance,
				v_state,
				callback: () => {
					setModal(null);
				},
			});
		}, 10000);

		setModal([..._modal]);
	};

	const handleMixerDismiss = () => {
		const i_state = `/idle`;
		set_lungo_state({ i_state, ia, instance });
		setModal(null);
	};

	const handleMixerStateChange = ({ state }) => {
		const i_state = `/c/${mixerOptionRef.current}/s?ch=${state.join(',')}`;
		set_lungo_state({ i_state, ia, instance });
	};

	// midiateca ----------

	const handleMidiatecaOptionSelection = ({ id, label }) => {
		if (label.indexOf('/') !== -1) {
			const frg = label.split('/');

			setInfo({ title: frg[0], subtitle: frg[1] });
		} else {
			setInfo({ title: label, subtitle: null });
		}

		// handle option, via global state
		console.log({ i_state: `/m/${id}`, v_state: `/${pathname}/?playing=1`, ia, instance, observe: true });
		set_lungo_state({ i_state: `/m/${id}`, v_state: `/${pathname}/?playing=1`, ia, instance, observe: true });

		H.push({
			pathname,
			search: '?playing=1',
		});
	};

	// debounce and change
	const handleSearchChange = (e) => {
		// handle search change
		const term = e.detail.value;

		if (term) {
			let result = [];
			local.groups.filter((g) => {
				const f = g.options.filter((e) => e.label.toLowerCase().indexOf(term.toLowerCase()) > -1);
				result = [...result, ...f];
			});
			setFiltered(result);
		} else {
			setFiltered(null);
		}
	};

	// --- quiz

	const handleQuizNextTap = () => {
		const state = qs.parse(window.location.search.replace('?', ''));
		const { p, c } = state;

		if (p && c) {
			const next = parseInt(p) + 1;

			if (hasNextQuiz({ code: c, next })) {
				// move pra nova pergunta
				const i_state = `/q/${c}/${next}`;
				const v_state = `/${locale}/i/${ia}/${instance}/q/s?c=${c}&p=${next}`;
				const ref = `/${locale}/i/${ia}/${instance}/q/s?c=${c}&p=${p}`;

				set_lungo_state({ v_state, i_state, ia, instance, ref, observe: true });
				setLoading(true);
				setModal(null);
			} else {
				// reset
				const i_state = `/idle`;
				const ref = `/${locale}/i/${ia}/${instance}/q/s?c=${c}&p=${p}`;
				const v_state = `/${locale}/i/${ia}/${instance}/o`;

				set_lungo_state({ v_state, i_state, ia, instance, ref, observe: true });
				setLoading(true);
				setModal(null);
			}
		}
	};

	const handleQuizDismissTap = () => {
		// reset
		const state = qs.parse(window.location.search.replace('?', ''));
		const { p, c } = state;

		if (p && c) {
			const i_state = `/idle`;
			const ref = `/${locale}/i/${ia}/${instance}/q/s?c=${c}&p=${p}`;
			const v_state = `/${locale}/i/${ia}/${instance}/o`;

			set_lungo_state({ v_state, i_state, ia, instance, ref, observe: true });
			setLoading(true);
			setModal(null);
		}
	};

	// zum ----
	const handleZoomPickOption = ({ option }) => {
		const state = retrieve();
		setInfo({ title: `${state.name}`, subtitle: `${T('common.instructions.info_zum_option')} ${option}` });
		set_lungo_state({ i_state: `/c/${option}`, v_state: `${pathname}/s?i=${option}`, ia, instance, ref: pathname, observe: true });
	};

	const handleZoomStopTap = () => {
		setPresentZoomControl(false);
		set_lungo_state({ i_state: `/idle`, v_state: pathname, ia, instance });
	};

	return (
		<IonPage>
			{!visible && <IonContent />}
			{visible && (
				<IonContent ref={contentRef} fullscreen>
					{ux !== `q` && (
						<Header
							onBack={handleBack}
							level={local?.level}
							title={local?.title[locale ?? 'pt']}
							subtitle={local?.subtitle[locale ?? 'pt']}
							content={local?.content[locale ?? 'pt']}
						/>
					)}
					{ux === `f` && <IonSearchbar mode='ios' placeholder={T('common.instructions.search_placeholder')} onIonChange={handleSearchChange} />}
					{ux === `f` && (
						<>
							{/* spacer */}
							<div className='w-full h-8' />

							{/* groups of content */}
							{!filtered &&
								local?.groups?.map((group, index) => (
									<ListG key={index} items={group?.options} title={group.label[locale ?? `pt`]} onSelect={handleMidiatecaOptionSelection} />
								))}
							{/* filtered content */}
							{filtered && filtered.length > 0 && (
								<ListG items={filtered} title={T('common.instructions.search')} onSelect={handleMidiatecaOptionSelection} />
							)}
							{/* filtered content --- empty search */}
							{filtered && filtered.length === 0 && (
								<>
									<ListG items={[]} title={T('common.instructions.search')} />
									<p className='w-full -mt-16 px-4'>{T('common.warning.empty_search')}</p>
								</>
							)}
							<IonFab vertical='bottom' horizontal='end' slot='fixed'>
								<motion.div whileTap={{ scale: 0.9 }} onTap={handleFabTop} className='w-16 h-16 bg-az rounded-full grid place-items-center'>
									<IonIcon icon={chevronUp} className='w-8 h-8 pb-1 fill-current text-white' />
								</motion.div>
							</IonFab>
						</>
					)}

					{ux !== 'f' && local?.options?.length > 20 && (
						<IonFab vertical='bottom' horizontal='end' slot='fixed'>
							<motion.div whileTap={{ scale: 0.9 }} onTap={handleFabTop} className='w-16 h-16 bg-az rounded-full grid place-items-center'>
								<IonIcon icon={chevronUp} className='w-8 h-8 pb-1 fill-current text-white' />
							</motion.div>
						</IonFab>
					)}

					{ux === `o` && (
						<>
							<List items={local?.options} onSelect={handleOptionSelection} />
							{ia === `hDjds9A` && (
								<List
									items={times(10, (e) => ({ key: `QLu6hnH-${e + 1 + 71}`, label: `Base ${e + 1} ${e < 4 ? `Vandal` : ``}`, filter: `` }))}
									onSelect={handleOptionSelection}
									header={T('common.instructions.pick_base')}
								/>
							)}
						</>
					)}

					{ux === `m` && (
						<>
							<List items={local?.options} onSelect={handleMixerOptionSelection} />
						</>
					)}

					{ux === `k` && <ListKaraoke items={local?.options} onBgSelect={handleKaraokeBgSelection} onSngSelect={handleKaraokeSngSelection} />}
					{ux === `k` && (
						<div className='h-24 grid place-items-center'>
							{/* enbled */}
							<motion.button className='pill' onTap={handleKaraokeStartTap}>
								{T('common.instructions.record')}
							</motion.button>
						</div>
					)}
					{ux === `q` && <UXQuiz modal={modal} ia={ia} instance={instance} onNext={handleQuizNextTap} onDismiss={handleQuizDismissTap} />}
					{ux === `m` && <UXMixer modal={modal} info={info} onChange={handleMixerStateChange} onDismiss={handleMixerDismiss} />}
					{ux === `z` && <UXZum onPickOption={handleZoomPickOption} />}
					<PlayStopControl title={info?.title} subtitle={info?.subtitle} open={presentPlayStop} onStop={handleOptionStop} />
					<KaraokeControl title={info?.title} subtitle={info?.subtitle} open={presentKaraokeControl} onStop={handleKaraokeStopTap} />
					<ZumControl title={info?.title} subtitle={info?.subtitle} open={presentZoomControl} onStop={handleZoomStopTap} />
				</IonContent>
			)}
		</IonPage>
	);
}

const Header = ({ onBack, level, title, subtitle, content }) => {
	return (
		<IonListHeader className='mb-2 p-0'>
			<div className='box-border'>
				<h3 className='px-4 flex justify-items-start items-center h-10' onClick={onBack}>
					<span className='text-xl mr-1'>&#8592;</span> {`${T('common.level')} ${level}`}
				</h3>
				<h4 className='px-4'>{title}</h4>
				{subtitle && <h5 className='px-4'>{subtitle}</h5>}
				<p className='px-4'>{content}</p>
			</div>
		</IonListHeader>
	);
};

const ListG = ({ onSelect, items, title }) => {
	const ref = useRef(null);
	const isVisible = useViewportSpy(ref, { threshold: 1 });
	const [render, setRender] = useState(false);

	useEffect(() => {
		if (isVisible) {
			setRender(true);
		}
	}, [isVisible]);

	const handleSelection = ({ id, label }) => {
		console.log('...........', id, label);
		if (onSelect) {
			onSelect({ id, label });
		}
	};

	return (
		<IonList ref={ref} className='mb-20'>
			{render && (
				<>
					<IonItemDivider mode='md' className='text-white text-xs h-12 uppercase mb-2 mt-0 bg-az' sticky={true}>
						<IonLabel>{title}</IonLabel>
					</IonItemDivider>

					{items?.map((item, index) => (
						<IonItem key={item.key} button detail={false} onClick={() => handleSelection({ id: item.key, label: item.label })} lines='full'>
							{/* {console.log(item)} */}
							<IonLabel>
								<ListItem label={item?.label?.toUpperCase()} capitalize />
							</IonLabel>
						</IonItem>
					))}
				</>
			)}
		</IonList>
	);
};

const List = ({ onSelect, header = null, items }) => {
	const handleSelection = ({ id, label }) => {
		if (onSelect) {
			onSelect({ id, label });
		}
	};

	return (
		<IonList className='mb-20'>
			<IonItemDivider mode='md' className='text-az text-xs uppercase mb-2 mt-16'>
				<IonLabel>{header || T('common.instructions.pick_option')}</IonLabel>
			</IonItemDivider>
			{items?.map((item) => (
				<IonItem key={item.key} button detail={false} onClick={() => handleSelection({ id: item.key, label: item.label })} lines='full'>
					<IonLabel>
						<ListItem label={item.label} />
					</IonLabel>
					{/* <IonIcon slot='end' icon={playCircle} className='fill-current text-az'></IonIcon> */}
				</IonItem>
			))}
		</IonList>
	);
};

const ListItem = ({ label, capitalize = false }) => {
	const [labelHilite, setLabelHilite] = useState(null);
	const [subLabel, setSubLabel] = useState(null);

	useEffect(() => {
		if (label.indexOf('/') > -1) {
			const tokens = label.split('/');
			setLabelHilite(tokens[0].trim());
			setSubLabel(tokens[1].trim());
		}
	}, [label]);

	return (
		<>
			<IonIcon slot='end' icon={playCircle} className='fill-current text-az'></IonIcon>

			{labelHilite && <span className='relative ml-1 font-rbt-bk text-az'>{labelHilite}</span>}
			{subLabel && <span className='relative block font-rbt mt-1 mb-1 text-az capitalize'>{subLabel}</span>}
			{!subLabel && !labelHilite && <span className={`relative ml-1 font-rbt-bk -pb-1 text-az`}>{label}</span>}

			{/* {labelHilite && <span className='block font-rbt-bk text-az'>{labelHilite}</span>}
			{subLabel && <span className='block font-rbt mt-1 mb-1 text-az capitalize'>{subLabel}</span>}
			{!subLabel && !labelHilite && <span className={`block font-rbt mt-1 mb-1 text-az`}>{label}</span>} */}
		</>
	);
};

const fundos = new Array(20).fill(0);

const ListKaraoke = ({ onSngSelect, onBgSelect, items }) => {
	const [sngSelected, setSngSelected] = useState(-1);
	const [bgSelected, setBgSelected] = useState(-1);

	const handleSngSelection = ({ item, index }) => {
		setSngSelected(index);

		if (onSngSelect) {
			onSngSelect({ sng: index + 1, name: items[index].label });
		}
	};

	const handleBgSelection = (index) => {
		setBgSelected(index);
		if (onBgSelect) {
			onBgSelect(index + 1);
		}
	};

	return (
		<IonList className='mt-7'>
			<IonItemDivider mode='md' className='text-az text-xs uppercase mb-2'>
				<IonLabel>{T('common.instructions.pick_song')}</IonLabel>
			</IonItemDivider>
			{items?.map((item, index) => (
				<IonItem key={`sng-${index}`} mode='ios' button detail={false} onClick={() => handleSngSelection({ item, index })} lines='full'>
					<IonCheckbox mode='ios' slot='start' color='primary' checked={index === sngSelected} />
					<IonLabel>
						<span className='font-rbt text-az'>{item.label}</span>
					</IonLabel>
				</IonItem>
			))}
			<IonItemDivider mode='md' className='text-az text-xs uppercase mb-2 mt-12'>
				<IonLabel>{T('common.instructions.pick_background')}</IonLabel>
			</IonItemDivider>

			{fundos.map((_, index) => (
				<IonItem key={`bg-${index}`} mode='md' button detail={false} onClick={() => handleBgSelection(index)} lines='full'>
					<IonCheckbox mode='ios' slot='start' color='primary' checked={bgSelected === index} ionChange={(e) => setBgSelected(index)} />
					<IonImg src={`/assets/karaoke/thumbs/k-${index + 1}.jpg`} />
				</IonItem>
			))}
		</IonList>
	);
};

const UXQuiz = ({ modal = null, ia, instance, onNext, onDismiss }) => {
	const [local, setLocal] = useState(null);
	const [correct, setCorrect] = useState(null);
	const [choosen, setChoosen] = useState(null);
	const [result, setResult] = useState(null);
	const [currPts, setCurrPts] = useGlobalState('currPts');
	const [message, setMessage] = useState(null);

	useEffect(() => {
		if (modal) {
			// reset all
			setChoosen(null);
			setCorrect(null);
			setResult(null);

			const state = qs.parse(window.location.search.replace('?', ''));
			const keys = ['A', 'B', 'C', 'D', 'E'];
			const { question, correct, code } = modal;
			const answers = [];

			keys.map((k) => {
				answers.push({ key: k, value: modal[k] });
			});

			setLocal({ question, correct, c: state.c, answers });
		}
	}, [modal]);

	const handleOptionSelection = (key) => {
		if (key) {
			const option = key.toUpperCase();
			const answer = local.correct.toUpperCase();

			setChoosen(option);
			setCorrect(answer);

			const visit = retrieve();

			const state = qs.parse(window.location.search.replace('?', ''));

			const _message = {
				name: visit.name,
				result: 'você errou sua pontuação é de',
				pts: currPts || 0,
				youneed: currPts < 40 ? `faltam ${40 - currPts} pontos para você ter o seu passaporte` : `você já tem o seu passaporte garantido!`,
				button: 'próxima pergunta',
			};

			if (option === answer) {
				// define next pts values
				const nextPts = currPts + 10;
				// update current state
				setCurrPts(nextPts);
				// define message
				_message.result = `você acertou, sua pontuação agora é`;
				_message.pts = nextPts;

				if (nextPts < 40) {
					_message.youneed = `faltam ${40 - nextPts} pontos para você ter o seu passaporte`;
				} else {
					_message.youneed = `você já tem o seu passaporte garantido!`;
				}
			}
			if (state.p === '3') {
				_message.button = 'encerrar';
			}

			// redefine a mensagem
			console.log(_message);
			setMessage(_message);
			setTimeout(() => {
				setResult(true);
			}, 1000);
		}
	};

	const handleNextTap = () => {
		if (message.button === 'encerrar') {
			if (onDismiss) {
				onDismiss();
			}
		} else {
			if (onNext) {
				setChoosen(null);
				setCorrect(null);
				setResult(null);
				onNext();
			}
		}
	};

	const handleDismiss = () => {
		if (onDismiss) {
			onDismiss();
		}
	};

	return (
		<IonContent>
			<div className='w-full h-auto px-4'>
				<header className='flex flex-row mt-4'>
					<img src='/assets/quiz/quiz-marca.svg' alt='' className='block w-28 h-28 object-contain' />

					<h3 className='w-full normal-case text-base px-2 items-center justify-items-center'>{local?.question}</h3>
				</header>
				<IonList className='mt-12'>
					{local?.answers.map((a) => (
						<IonItem
							key={a.key}
							className={`${
								correct && correct === a.key?.toUpperCase()
									? `text-green-600`
									: choosen && choosen === a.key?.toUpperCase()
									? `text-red-600`
									: `text-az`
							} `}
							mode='md'
							button
							detail={false}
							onClick={() => handleOptionSelection(a.key)}
							lines='none'>
							<div className='w-full h-8 flex flex-row '>
								<span className='w-12 font-g-bk text-4xl flex justify-items-center items-end'>{a.key}</span>
								<span className='w-full font-rbt text-base flex justify-items-center items-end p-2 pl-4 '>{a.value}</span>
							</div>
						</IonItem>
					))}
				</IonList>
				<div className='absolute h-24 bottom-2 grid place-items-center'>
					<motion.button whileTap={{ scale: 0.9 }} className='pill' onTap={handleDismiss}>
						encerrar
					</motion.button>
				</div>

				<IonModal isOpen={result !== null} cssClass='quiz-modal' backdropDismiss={false} swipeToClose={false} presentingElement={undefined}>
					<IonContent>
						<div className='w-full h-full text-center bg-az flex flex-col justify-items-center items-center'>
							<h4 className=' w-[60vw] font-rbt text-lg text-white normal-case mt-8'>
								<span className='font-rbt-bk'>{message?.name},</span> {message?.result}
							</h4>
							<div className='w-[50vw] h-[50vw] mt-8 border-4 border-white rounded-full grid place-items-center text-white text-7xl font-g-bk'>
								{message?.pts}
							</div>
							<p className='text-white text-sm text-center font-rbt w-[60%]'>{message?.youneed}</p>
							<div className='absolute h-24 bottom-2 grid place-items-center'>
								<motion.button whileTap={{ scale: 0.9 }} className='pill-inv' onTap={handleNextTap}>
									{message?.button}
								</motion.button>
							</div>
						</div>
					</IonContent>
				</IonModal>
			</div>
		</IonContent>
	);
};

const instruments = fill(Array(8), 1);

const UXMixer = ({ modal = null, info, onDismiss, onChange }) => {
	const [local, setLocal] = useState(null);
	const [state, setState] = useState(null);

	useEffect(() => {
		if (modal) {
			setLocal([...modal]);
			const _state = fill(Array(modal.length), 1);
			setState(_state);
		}
	}, [modal]);

	const handleSelection = ({ checked, index }) => {
		const _state = [...state];
		_state[index] = checked ? 1 : 0;
		setState([..._state]);

		const _local = [...local];
		_local[index].checked = checked;
		setLocal([..._local]);

		if (onChange) {
			onChange({ state: _state });
		}
	};

	const handleDismiss = () => {
		console.log('dismiss');
		if (onDismiss) {
			onDismiss();
		}
	};

	return (
		<IonModal isOpen={modal !== null} swipeToClose={true} presentingElement={undefined}>
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot='end'>
							<motion.div onTap={handleDismiss} className='text-az uppercase px-4 font-g-bk text-xs p-4'>
								{T('common.instructions.close')}
							</motion.div>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<h5 className='w-full px-4'>{info?.title}</h5>
					<IonItemDivider mode='md' className='text-az text-xs uppercase mb-2 mt-12'>
						<IonLabel>{T('common.instructions.pick_instrument')}</IonLabel>
					</IonItemDivider>
					{local?.map((item, index) => (
						<IonItem mode='ios' button detail={false} lines='full'>
							<IonCheckbox
								mode='ios'
								checked={item.checked}
								onIonChange={(e) => handleSelection({ checked: e.detail.checked, index })}
								slot='start'
								color='primary'
							/>
							<IonLabel>
								<span className='font-rbt text-sm text-az uppercase'>{item.label}</span>
							</IonLabel>
						</IonItem>
					))}
				</IonContent>
			</IonPage>
		</IonModal>
	);
};

const _rows = fill(Array(5), 1);
const _cols = fill(Array(3), 1);

const UXZum = ({ onPickOption }) => {
	const handleZumOptionTap = (option) => {
		if (onPickOption) {
			onPickOption({ option });
		}
	};

	// montar os 15 bts.
	return (
		<>
			<IonItemDivider mode='md' className='text-az text-xs uppercase mt-10'>
				<IonLabel>{T('common.instructions.pick_option')}</IonLabel>
			</IonItemDivider>
			<IonGrid className='mb-10 mt-0'>
				{_rows.map((_, i) => (
					<IonRow>
						{_cols.map((_, ii) => (
							<IonCol className='grid place-items-center pointer-events-none'>
								<motion.div
									whileTap={{ scale: 0.9 }}
									onTap={() => handleZumOptionTap(i * 3 + (ii + 1))}
									className='w-full h-28 border-az border grid place-items-center font-g-bk text-az text-2xl pointer-events-auto'>
									{i * 3 + (ii + 1)}
								</motion.div>
							</IonCol>
						))}
					</IonRow>
				))}
			</IonGrid>
		</>
	);
};

const PlayStopControl = ({ open = false, onStop, title = 'title', subtitle = 'subtitle' }) => {
	const handleTap = () => {
		if (onStop) {
			onStop();
		}
	};

	return (
		<IonModal
			mode='ios'
			isOpen={open}
			cssClass='player-modal'
			backdropDismiss={false}
			className='flex items-end pt-20 overflow-hidden'
			presentingElement={undefined}>
			<motion.div onTap={handleTap} className='w-full h-full flex flex-col justify-items-center items-center px-8'>
				{title && <h5 className='w-full flex-grow-0 text-white text-xl m-0 mt-8 p-0 font-g-bk uppercase text-center'>{title}</h5>}
				{subtitle && <h5 className='w-full h-2 flex-grow-0 text-white text-sm mt-2 m-0 p-0 font-g uppercase text-center'>{subtitle}</h5>}
				<div className='w-32 h-32 my-auto grid place-items-center'>
					<div className='w-28 h-28 -mt-4'>
						<svg viewBox='0 0 120 120'>
							<rect x='57.5' y='5.2' fill='white' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -24.8528 59.9999)' width='5.1' height='109.6' />
							<rect x='5.2' y='57.5' fill='white' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -24.8528 60.0001)' width='109.6' height='5.1' />
						</svg>
					</div>
					<div className='uppercase text-white mt-4'>{T('common.instructions.close')}</div>
				</div>
			</motion.div>
		</IonModal>
	);
};

const KaraokeControl = ({ open = false, onStop, title = 'title', subtitle }) => {
	console.log('subtitle', subtitle);
	const handleTap = () => {
		if (onStop) {
			onStop();
		}
	};

	return (
		<IonModal
			mode='ios'
			isOpen={open}
			backdropDismiss={false}
			cssClass='player-modal'
			className='flex items-end pt-20 overflow-hidden'
			presentingElement={undefined}>
			<motion.div onTap={handleTap} className='w-full h-full flex flex-col justify-items-center items-center px-8'>
				{title && <h5 className='w-full flex-grow-0 text-white text-xl m-0 mt-8 p-0 font-g-bk uppercase text-center'>{title}</h5>}
				{subtitle && <h5 className='w-full h-2 flex-grow-0 text-white text-sm mt-2 m-0 p-0 font-g uppercase text-center'>{subtitle}</h5>}
				<div className='w-32 h-32 my-auto grid place-items-center'>
					<div className='w-28 h-28 -mt-4'>
						<svg viewBox='0 0 120 120'>
							<rect x='57.5' y='5.2' fill='white' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -24.8528 59.9999)' width='5.1' height='109.6' />
							<rect x='5.2' y='57.5' fill='white' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -24.8528 60.0001)' width='109.6' height='5.1' />
						</svg>
					</div>
					<div className='uppercase text-white mt-4'>{T('common.instructions.close')}</div>
				</div>
			</motion.div>
		</IonModal>
	);
};

const ZumControl = ({ open = false, onStop, title = 'title', subtitle }) => {
	console.log('subtitle', subtitle);
	const handleTap = () => {
		if (onStop) {
			onStop();
		}
	};

	return (
		<IonModal
			mode='ios'
			isOpen={open}
			backdropDismiss={false}
			cssClass='player-modal'
			className='flex items-end pt-20 overflow-hidden'
			presentingElement={undefined}>
			<motion.div onTap={handleTap} className='w-full h-full flex flex-col justify-items-center items-center px-8'>
				{title && <h5 className='w-full flex-grow-0 text-white text-xl m-0 mt-8 p-0 font-g-bk uppercase text-center'>{title}</h5>}
				{subtitle && <h5 className='w-full h-2 flex-grow-0 text-white text-sm mt-2 m-0 p-0 font-g uppercase text-center'>{subtitle}</h5>}
				<div className='w-32 h-32 my-auto grid place-items-center'>
					<div className='w-28 h-28 -mt-4'>
						<svg viewBox='0 0 120 120'>
							<rect x='57.5' y='5.2' fill='white' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -24.8528 59.9999)' width='5.1' height='109.6' />
							<rect x='5.2' y='57.5' fill='white' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -24.8528 60.0001)' width='109.6' height='5.1' />
						</svg>
					</div>
					<div className='uppercase text-white mt-4'>{T('common.instructions.close')}</div>
				</div>
			</motion.div>
		</IonModal>
	);
};
