import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { Loader } from './components';
import { resolve } from './state';
import * as p from './pages';
import './global';

export default function App() {
	return (
		<>
			<IonApp style={{ height: window.innerHeight }}>
				<IonReactRouter>
					<Route
						render={({ history }) => {
							(async () => {
								await resolve({ history });
							})();
						}}
					/>
					<IonRouterOutlet mode='ios'>
						<Route exact path='/' component={p.Loading} />
						<Route exact path='/ln' component={p.PickLanguage} />
						<Route exact path='/:locale/u' component={p.SignUp} />
						<Route exact path='/:locale/s' component={p.Springboard} />
						<Route path={['/:locale/i/:ia/:instance?/:ux?']} component={p.Installation} />
					</IonRouterOutlet>
				</IonReactRouter>
			</IonApp>
			<Loader />
		</>
	);
}
