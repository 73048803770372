import { useEffect } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { useGlobalState } from '../state';

export default function Loading({ history }) {
	const [_, setLoading] = useGlobalState('loading');
	useEffect(() => {
		return () => {
			setLoading(false);
		};
	}, []);
	return (
		<IonPage>
			<IonContent>
				<div className='w-screen h-screen text-2xl bg-white text-white grid place-items-center' />
			</IonContent>
		</IonPage>
	);
}
