import { IonContent, IonHeader, IonItem, IonPage, IonLabel, IonInput, IonDatetime, useIonAlert } from '@ionic/react';
import { isPlatform } from '@ionic/core';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { T, useGlobalState, store, sign_in } from '../state';
import { Device } from '@capacitor/device';
import { useKeyboardState } from '@ionic/react-hooks/keyboard';

import first from 'lodash/first';

import { Marca } from '../components';

export default function Signup({ history }) {
	// para ajustar o preenchimento dos dados
	const viewRef = useRef(null);
	// deconstruct locale
	const { locale } = useParams();

	const { isOpen, keyboardHeight } = useKeyboardState();

	const [, setLocale] = useGlobalState('locale');
	const [visit, setVisit] = useState({ name: null, email: null, birthday: null, device: null });
	// const [visit, setVisit] = useState({ name: `daniel`, email: `danielmorena@gmail.com`, birthday: `1974-7-17`, device: null });
	const [present] = useIonAlert();

	// willenter, reset visit
	useEffect(() => {
		setVisit({ name: null, email: null, bithday: null, device: null });
	}, []);

	// store the choosen locale
	useEffect(() => {
		store({ locale });
		setLocale(locale);
	}, [locale]);

	useEffect(() => {
		if (viewRef.current) {
			if (isOpen) {
				// move field up, based on focus
				const offset = isPlatform('ios') ? -(keyboardHeight - 50) : keyboardHeight - 50;
				viewRef.current.style.setProperty('transform', `translate3d(0, ${offset}px, 0)`);
			} else {
				viewRef.current.style.setProperty('transform', `translate3d(0, 0px, 0)`);
			}
		}
	}, [isOpen, keyboardHeight]);

	const validate = (email) => {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email.trim()).toLowerCase());
	};

	const resolve = async () => {
		// get uuid from info
		const info = await Device.getId();

		// deconstruct name, email e birthday
		let { name, email, birthday } = visit;
		email = email.replace(/\s/g, '');
		console.log('===', name, email, birthday);
		// if all
		if (name && email && birthday) {
			if (!validate(email)) {
				// notify, return
				present({
					header: T('common.warning.header'),
					message: T('common.warning.verify_email'),
					buttons: ['ok'],
				});

				return;
			}

			if (birthday.indexOf(`2021`) === 0) {
				// notify, early
				present({
					header: T('common.warning.header'),
					message: T('common.warning.verify_birthday'),
					buttons: ['ok'],
				});

				return;
			}

			const device = info.uuid;

			// store between browser pages
			store({ name, email, birthday, device });

			// sign in na api
			const signed_in = await sign_in({ name, email, birthday, device });

			// se tiver erro notifica
			if (signed_in.error) {
				// notify error
				present({
					header: T('common.warning.header'),
					message: T('common.warning.sign_in_error'),
					buttons: ['ok'],
				});
			} else {
				// tudo certo, vamos para o springboard
				store({ state: `/${locale}/s` });
				history.push(`/${locale}/s`);
			}
		}
	};

	const handleNameChange = (e) => {
		setVisit((curr) => ({ ...curr, name: e.target.value }));
	};

	const handleEmailChange = (e) => {
		setVisit((curr) => ({ ...curr, email: e.target.value }));
	};

	const handleBirthdayChange = (e) => {
		setVisit((curr) => ({ ...curr, birthday: first(e.target.value.split('T')) }));
	};

	return (
		<IonPage ref={viewRef}>
			<IonHeader>
				<Marca className='fixed w-32 left-10 top-4 z-10 fill-current text-az' />
			</IonHeader>
			<IonContent fullscreen>
				<div className='pt-24'>
					<h2 className='ml-6 text-4xl font-rbt-lt text-az'>{T('common.signup.welcome')}</h2>
					<IonItem lines='full' className='mt-16'>
						<IonLabel position='fixed'>
							<span className='text-az ml-5 uppercase text-xs'>{T('common.signup.name')}</span>
						</IonLabel>
						<IonInput
							value={visit?.name}
							placeholder={T('common.signup.placeholder_name')}
							onIonChange={handleNameChange}
							// onFocus={handleNameFocus}
							className='text-az relative ml-5 uppercase text-xs'
						/>
					</IonItem>
					<IonItem lines='full' className='mt-6'>
						<IonLabel position='fixed'>
							<span className='text-az ml-5 uppercase text-xs'>{T('common.signup.email')}</span>
						</IonLabel>
						<IonInput
							value={visit?.email}
							placeholder={T('common.signup.placeholder_email')}
							onIonChange={handleEmailChange}
							// onFocus={() => console.log('email focus')}
							className='text-az relative ml-5 uppercase text-xs'
						/>
					</IonItem>
					<IonItem className='mt-6' lines='full'>
						<IonLabel>
							<span className='text-az ml-5 uppercase text-xs'>{T('common.signup.birthday')}</span>
						</IonLabel>
						<IonDatetime
							displayFormat='DD MM YYYY'
							mode='md'
							placeholder={T('common.signup.select_date')}
							className='w-[57%] px-0 text-az mx-5 uppercase text-xs'
							onIonChange={handleBirthdayChange}></IonDatetime>
					</IonItem>
					<div className='fixed bottom-6 grid place-items-center w-full'>
						<motion.button className='pill' onTap={resolve}>
							entrar
						</motion.button>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
}
