import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useViewportSpy } from 'beautiful-react-hooks';

export default ({ className, src, ...rest }) => {
	const ref = useRef();
	const isVisible = useViewportSpy(ref);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (src && isVisible) {
			ref.current.src = src;
		}
	}, [src, isVisible]);

	return (
		<motion.img
			ref={ref}
			initial={`loading`}
			animate={loading ? `loading` : `visible`}
			onLoad={() => setLoading(false)}
			variants={{ visible: { opacity: 1 }, loading: { opacity: 0 } }}
			className={`opacity-0 ${className}`}
			{...rest}
		/>
	);
};
