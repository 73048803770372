import { useState, useEffect, useRef, useImperativeHandle, forwardRef, useLayoutEffect } from 'react';
import { IonContent, IonPage, IonModal, useIonAlert } from '@ionic/react';
import { isPlatform } from '@ionic/core';
import { useParams } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useViewportSpy, useGlobalEvent } from 'beautiful-react-hooks';
import { Portal } from 'react-portal';
import { useGlobalState, pair, getSpringBoardModel, getIaModel, getIaUx, getNovaMusicaList, retrieve, T } from '../state';
import { Lzimg, Marca, BtPill, List } from '../components';
import { useKeyboardState } from '@ionic/react-hooks/keyboard';

const alerts = { INCOMPLETE_PIN: 0 };

// bg-nv-0 bg-nv-1 bg-nv-2 bg-nv-3

export default function Springboard({ history }) {
	// extract pin component?
	const deselectRef = useRef(null);
	const tokenRef = useRef(null);

	const [presentAlert] = useIonAlert();
	const [, setLoading] = useGlobalState('loading');
	const [currPts] = useGlobalState('currPts');
	const [extraList, setExtraList] = useState(null);

	const { locale } = useParams();
	const [modal, setModal] = useState(null);
	const [levels, setLevels] = useState(null);
	const [structure] = useGlobalState('structure');
	const [currLvl] = useGlobalState('currLvl');

	// investigate life cycle methods

	// take from global structure
	useEffect(() => {
		if (structure) {
			// implement this on state
			const levels = getSpringBoardModel();
			// define museu levels
			setLevels([...levels]);
		}
	}, [structure]);

	const present = (id, deselect) => {
		// pass the ref for the current lvl which contains the auto selected thumb
		deselectRef.current = deselect;

		// define ia data by id
		const ia = id;

		// get modal model
		const _modal = getIaModel({ ia, locale });

		// lida com o exception
		if (_modal.id.trim() === 'b16tqUB') {
			const _extralist = getNovaMusicaList();
			setExtraList(_extralist);
		} else {
			setExtraList(null);
		}

		// define the modal an present it
		setModal({ ..._modal });

		// auto focus
		if (_modal.pair) {
			setTimeout(() => {
				tokenRef.current.focus();
			}, 1000);
		}
	};

	const dismiss = () => {
		setModal(null);
		setTimeout(() => {
			deselectRef.current();
		}, 300);
	};

	const presentTypedAlert = (type) => {
		let definition = {};
		switch (type) {
			case alerts.INCOMPLETE_PIN: {
				definition = {
					header: T('common.warning.header'),
					message: T('common.warning.verify_pin'),
					buttons: ['ok'],
					onDidDismiss: (e) => console.log('did dismiss'),
				};
				break;
			}
		}

		presentAlert({ ...definition });
	};

	const handlePinInputDone = async ({ otp, ia = '0' }) => {
		try {
			if (otp.length < 8) {
				// alert to type all digits
				presentTypedAlert(alerts.INCOMPLETE_PIN);
			} else {
				// put app in "loading" state
				setLoading(true);

				// try to pair
				const instance = `${otp}`.slice(0, 2);
				const paired = await pair({ instance, ia_uid: ia, otp });

				// need to handle pair "errors"
				// if paired
				if (paired.error) {
					if (paired.error === 'not-match') {
						presentAlert({
							header: T('common.warning.header'),
							message: T('common.warning.pair_match_error'),
							buttons: ['ok'],
							onDidDismiss: (e) => dismiss(),
						});
					}
					if (paired.error === 'bad-request') {
						presentAlert({
							header: T('common.warning.header'),
							message: T('common.warning.pair_unknow_error'),
							buttons: ['ok'],
							onDidDismiss: (e) => dismiss(),
						});
					}
					if (paired.error === 'locked') {
						presentAlert({
							header: T('common.warning.header'),
							message: T('common.warning.pair_locked_error'),
							buttons: ['ok'],
							onDidDismiss: (e) => dismiss(),
						});
					}
				} else {
					// dismiss the modal
					setTimeout(dismiss, 500);
					// navigate
					// method to define the ux,
					const ux = getIaUx({ ia, instance });
					// navigate to correct "url"
					setTimeout(() => history.push(`/${locale}/i/${ia}/${instance}/${ux}`), 1400);
				}
			}
		} catch (error) {
			// handle server errors
			console.log('error', error);
		} finally {
			// dismiss "loading" feedback
			setLoading(false);
		}
	};

	const capitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
	};

	const presentPassport = () => {
		const state = retrieve();
		const pts = currPts ? currPts : 0;
		let message = ``;
		let buttons = [];
		let header = ``;

		if (state.locale === `pt`) {
			if (currPts < 40) {
				message = `<b>${capitalize(state.name)},</b> você tem ${pts} pontos. <br/>Ainda faltam ${
					40 - currPts
				}, para você <br/>garantir o seu Passporte Quiz.`;
			} else {
				message = `<b>${capitalize(state.name)},</b> você tem ${pts} pontos, e está com o seu Passporte Quiz garantido.`;
			}
			buttons = [`Entendi!`];
			header = 'Passaporte Quiz';
		}

		if (state.locale === `en`) {
			message = `<b>${capitalize(state.name)},</b> you've got your Quiz Passport.`;
			buttons = [`Got it!`];
			header = 'Quiz Passaport';
		}

		presentAlert({
			header,
			cssClass: `passport`,
			message,
			buttons,
		});
	};

	return (
		<IonPage className='h-full'>
			<IonContent className='h-full'>
				{/* marca */}
				<Marca className='fixed w-24 left-10 top-4 z-10' />

				{/* springboard  */}
				<div className='fixed left-0 top-0 overflow-y-hidden w-full h-full grid place-items-center text-white text-8xl'>
					<div className='min-w-screen h-full flex flex-row'>
						{levels?.map((level, index) => (
							<Level key={level.id} index={index} {...level} locale={locale} onSelect={present} />
						))}
					</div>
				</div>

				{/* level name to be changed when view is in viewport */}
				<h2 className='fixed font-rbt -mt-2 top-0 pl-10 uppercase text-black text-sm' style={{ top: `16vh` }}>{`${currLvl}`}</h2>

				{/* logo quiz + pontuação */}
				<motion.div className='fixed right-0 top-1 mr-2 grid place-items-center' onTap={presentPassport}>
					<img src='/assets/quiz/quiz-marca.svg' alt='' className='block w-20 h-20 object-contain' />
					{locale === `pt` && (
						<span className='relative font-rbt text-xs text-black mr-2 mt-0 uppercase'>{`${currPts ? currPts : 0} ${T(
							'common.instructions.pts',
						)}`}</span>
					)}
				</motion.div>

				<div id='trans-img' className='fixed left-0 top-0 w-screen h-full pointer-events-none z-20' />

				{/* pair modal */}
				<IonModal isOpen={modal !== null} swipeToClose={true} presentingElement={undefined}>
					<IonContent>
						<div className={`${extraList ? `h-auto` : `h-full`} box-border`}>
							<div className='w-full h-12 grid place-items-center' onClick={dismiss}>
								<div className='rounded-full w-12 h-2 bg-az' />
							</div>
							<h4 className='px-8 mt-4'>{modal?.title[locale ?? 'pt']}</h4>
							<p className='px-8'>{modal?.content[locale ?? 'pt']}</p>
						</div>
						{modal?.pair && (
							<>
								<InputPin
									ref={tokenRef}
									onDone={handlePinInputDone}
									ia={modal?.id}
									className='absolute ml-8'
									style={{ top: isPlatform('ios') ? `60vh` : `70vh` }}
								/>

								<BtPill
									onTap={() => handlePinInputDone({ token: tokenRef.current.getToken(), ia: modal?.id })}
									className='absolute bottom-8 mx-auto grid place-items-center'>
									{T('common.instructions.connect')}
								</BtPill>
							</>
						)}
						{!modal?.pair && extraList && (
							<>
								<List items={extraList} />
								<BtPill onTap={dismiss} className='mx-auto my-8 grid place-items-center'>
									ok
								</BtPill>
							</>
						)}

						{!modal?.pair && !extraList && (
							<BtPill onTap={dismiss} className='absolute bottom-8 mx-auto grid place-items-center'>
								ok
							</BtPill>
						)}
					</IonContent>
				</IonModal>
			</IonContent>
		</IonPage>
	);
}

const LevelImpl = ({ ias, label, color, locale, onSelect }, ref) => {
	const lvlRef = useRef(null);
	const imgRef = useRef(null);
	const figRef = useRef(null);

	const controls = useAnimation();

	const isVisible = useViewportSpy(lvlRef, { threshold: 1 / ias?.length });
	const [, setCurrLvl] = useGlobalState('currLvl');

	useEffect(() => {
		if (isVisible) {
			setCurrLvl(label);
		}
	}, [isVisible]);

	useImperativeHandle(ref, () => ({ deselect }));

	const select = async () => {
		const b = figRef.current.getBoundingClientRect();
		await controls.start({
			x: [b.x, 0],
			y: [b.y, 0],
			width: [b.width, window.innerWidth],
			height: [b.height, window.innerHeight],
			opacity: [0, 1],
			display: `block`,
			transition: { duration: 0.4, transition: { ease: [0.2, 0.92, 0.09, 1] }, opacity: { duration: 0.1 } },
		});
	};

	const deselect = async () => {
		const b = figRef.current.getBoundingClientRect();
		await controls.start({
			x: [0, b.x],
			y: [0, b.y],
			width: [window.innerWidth, b.width],
			height: [window.innerHeight, b.height],
			opacity: [1, 0],
			display: `block`,
			transitionEnd: { display: `none` },
			transition: { duration: 0.4, transition: { ease: [0.2, 0.92, 0.09, 1] }, opacity: { duration: 0.1, delay: 0.3 } },
		});
	};

	const handleSelect = (id, target) => {
		figRef.current = target.current;

		const entry = ias.find((e) => e.id === id);

		if (entry) {
			imgRef.current.src = `/assets/springboard/${entry.media}`;
			setImmediate(async () => {
				await select();
				onSelect(id, deselect);
			});
		}
	};

	return (
		<div ref={lvlRef} className={`relative ${color} min-w-screen h-full flex flex-row space-x-10 px-10 pt-20`}>
			{ias?.map((ia, index) => (
				<Ia key={ia.id} index={index} {...ia} locale={locale} onSelect={handleSelect} />
			))}

			<Portal node={document?.querySelector('#trans-img')}>
				{/* the transition image */}
				<motion.figure
					animate={controls}
					className='fixed top-0 h-full hidden'
					style={{ width: `70vw`, height: Math.min(window.innerHeight * 0.67, 400) }}>
					<img ref={imgRef} className={`block w-full h-full object-cover bg-gray-300`} />
				</motion.figure>
			</Portal>
		</div>
	);
};
const Level = forwardRef(LevelImpl);

const Ia = ({ id, instance_id, name, media = ``, locale, onSelect }) => {
	const figRef = useRef(null);

	const handle = () => {
		onSelect(id, figRef);
	};

	// should consider some abstraction to handle different cel heights
	return (
		// <section className='my-auto flex flex-col'>
		<section className='mt-[10vh] flex flex-col'>
			<motion.figure
				ref={figRef}
				className='relative h-full '
				style={{ width: `70vw`, height: Math.min(window.innerHeight * 0.67, 400) }}
				onClick={handle}>
				<div className='absolute mix-blend-overlay h-full w-full bg-black opacity-40'></div>
				{media && <Lzimg src={`/assets/springboard/${media}`} alt='' className={`block absolute w-full h-full object-cover bg-gray-300`} />}
			</motion.figure>
			<figcaption className={`font-rbt-bk text-black text-sm mt-2 font-g-bk uppercase`}>{name[locale]}</figcaption>
		</section>
	);
};

const _empty = ['', '', '', '', '', '', '', ''];

const InputPinImpl = ({ onDone = () => {}, ia, className, style }, ref) => {
	const [pin, setPin] = useState(_empty);
	const [currPin, setCurrPin] = useState(-1);

	useGlobalEvent('keyup', null, (e) => {
		if (e.key === `Backspace` || e.key === `Delete`) {
			const next = currPin - 1;

			if (next >= 0) {
				setCurrPin(next);
				// which is the next pin
				const nextpin = document.querySelector(`[data-index="${next}"]`);
				// if there's a next pin
				if (nextpin) {
					// focus
					nextpin.focus();
					nextpin.value = '';
				}
			}
		}
	});
	useImperativeHandle(ref, () => ({ reset, focus, getToken }));

	const reset = () => {
		// reset all values when initing or when called
		setPin(_empty);
		setCurrPin(-1);

		for (let i = 0; i < 8; i++) {
			const pin = document.querySelector(`[data-index="${i}"]`);

			if (pin) {
				pin.value = ``;
			}
		}
	};

	useLayoutEffect(() => {
		setTimeout(reset, 100);
	}, []);

	const focus = () => {
		document.querySelector(`[data-index="0"]`).focus();
		setCurrPin(0);
	};

	const getToken = () => pin.join(``);

	const handlePinFocus = (e) => {
		e.target.placeholder = '';
	};

	const handlePinChange = (index, value) => {
		// save the value
		const curr = [...pin];
		curr[index] = value;

		setPin([...curr]);

		// focus on next pin digit
		const next = index + 1;

		if (next < 8) {
			// define the current pin state
			setCurrPin(next);
			// which is the next pin
			const nextpin = document.querySelector(`[data-index="${next}"]`);
			// if there's a next pin
			if (nextpin) {
				// focus
				nextpin.focus();
			}
		} else {
			// redefine token
			const otp = curr.join(``);
			// set curr pin
			setCurrPin(-1);
			// remove focus from pin
			document.querySelector(`[data-index="7"]`).blur();
			// notify done
			onDone({ otp, ia });
		}
	};

	return (
		<div className={`flex flex-row space-x-2 flex-shrink-0 flex-grow-0 my-auto ${className}`} style={{ ...style }}>
			{pin?.map((_, index) => (
				<div key={`pin-${index}`} className={`border-b-4 w-8 h-12 border-az flex-grow-1 ${index === currPin ? `animate-pulse` : ``}`}>
					<input
						data-index={index}
						type='number'
						onFocus={handlePinFocus}
						onChange={(e) => handlePinChange(index, e.target.value)}
						inputMode='decimal'
						className='w-8 h-12 bg-transparent grid place-items-center font-rbt text-4xl text-az text-center'
					/>
				</div>
			))}
		</div>
	);
};

const InputPin = forwardRef(InputPinImpl);
