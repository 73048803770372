import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

export default ({ className }) => {
	return (
		<svg className={`${className}`} viewBox='0 0 154.3 97.2'>
			<path d='M8.7 27.7C3.9 27.7.4 24 .4 19.4c0-4.6 3.5-8.4 8.5-8.4 3.1 0 4.9 1 6.4 2.5L13 16c-1.3-1.1-2.5-1.8-4.1-1.8-2.7 0-4.7 2.3-4.7 5.1s1.9 5.1 4.7 5.1c1.9 0 3-.7 4.3-1.9l2.3 2.3c-1.8 1.8-3.6 2.9-6.8 2.9zM18.4 27.4V11.2H22v16.2h-3.6zM32.4 27.4H26V11.2h6.3c5.1 0 8.6 3.5 8.6 8.1.1 4.5-3.4 8.1-8.5 8.1zm4.8-8.1c0-2.9-2-4.9-4.9-4.9h-2.8v9.8h2.8c3 0 4.9-2 4.9-4.9zM55.1 27.4l-1.5-3.6h-6.9l-1.5 3.6h-3.6l7-16.3h3.3l7 16.3h-3.8zm-4.9-12.1L48 20.6h4.3l-2.1-5.3zM67.6 27.4h-6.3V11.2h6.3c5.1 0 8.6 3.5 8.6 8.1 0 4.5-3.5 8.1-8.6 8.1zm4.9-8.1c0-2.9-2-4.9-4.9-4.9h-2.8v9.8h2.8c2.9 0 4.9-2 4.9-4.9zM79.4 27.4V11.2h12.2v3.2H83v3.3h7.6v3.2H83v3.4h8.8v3.2H79.4zM108.2 27.4h-6.3V11.2h6.3c5.1 0 8.6 3.5 8.6 8.1 0 4.5-3.5 8.1-8.6 8.1zm4.9-8.1c0-2.9-2-4.9-4.9-4.9h-2.8v9.8h2.8c2.9 0 4.9-2 4.9-4.9zM130.9 27.4l-1.5-3.6h-6.9l-1.5 3.6h-3.6l7-16.3h3.3l7 16.3h-3.8zM126 15.3l-2.2 5.3h4.3l-2.1-5.3z' />
			<g>
				<path d='M19.1 58.4V43.2l-6.5 9.9h-.1L6 43.3v15.1H1V35.1h5.5l6.1 9.8 6.1-9.8h5.5v23.2h-5.1zM39.4 58.7c-6.2 0-10.1-3.5-10.1-10.3V35.1h5.1v13.1c0 3.8 1.9 5.7 5 5.7s5-1.9 5-5.6V35.1h5.1v13.1c.1 7.1-3.8 10.5-10.1 10.5zM63.1 58.7c-3.5 0-7.1-1.2-9.9-3.7l3-3.6c2.1 1.7 4.3 2.8 6.9 2.8 2.1 0 3.4-.8 3.4-2.2v-.1c0-1.3-.8-2-4.7-3-4.7-1.2-7.7-2.5-7.7-7.1v-.1c0-4.2 3.4-7 8.1-7 3.4 0 6.3 1.1 8.6 3l-2.7 3.9c-2.1-1.4-4.1-2.3-6-2.3s-3 .9-3 2v.1c0 1.5 1 2 5 3.1 4.7 1.2 7.4 2.9 7.4 7v.1c.2 4.5-3.4 7.1-8.4 7.1zM76.3 58.4V35.1h5.1v23.2h-5.1zM98.1 58.8c-6.8 0-11.9-5.3-11.9-12v-.1c0-6.6 5-12 12.1-12 4.4 0 7 1.5 9.2 3.6l-3.3 3.8c-1.8-1.6-3.6-2.6-5.9-2.6-3.9 0-6.7 3.3-6.7 7.2v.1c0 4 2.8 7.3 6.7 7.3 2.7 0 4.3-1.1 6.1-2.7l3.3 3.3c-2.4 2.5-5.1 4.1-9.6 4.1zM129.1 58.4l-2.1-5.2h-9.8l-2.1 5.2h-5.2l10-23.4h4.7l10 23.4h-5.5zM122 41.1l-3.1 7.5h6.2l-3.1-7.5z' />
			</g>
			<g>
				<path d='M7.5 84.1H.3V65.7h7.2c5.8 0 9.8 4 9.8 9.1v.1c0 5.2-4 9.2-9.8 9.2zm5.6-9.2c0-3.3-2.2-5.5-5.5-5.5H4.4v11.1h3.1c3.3 0 5.6-2.3 5.6-5.6zM33.2 84.1L31.6 80h-7.8l-1.7 4.1H18l7.9-18.5h3.7l7.9 18.5h-4.3zm-5.5-13.6l-2.4 6h4.9l-2.5-6zM56.9 84.1h-8.7V65.7h8.5c3.8 0 6.1 1.9 6.1 4.8v.1c0 2.1-1.1 3.2-2.4 4 2.1.8 3.4 2 3.4 4.5v.1c0 3.2-2.7 4.9-6.9 4.9zm1.9-12.9c0-1.2-.9-1.9-2.7-1.9h-4v3.9h3.7c1.8 0 3-.6 3-2zm1 7.3c0-1.2-.9-2-3-2h-4.7v4h4.8c1.8.1 2.9-.6 2.9-2zM80.8 84.1L79.1 80h-7.8l-1.7 4.1h-4.1l7.9-18.5h3.7L85 84.1h-4.2zm-5.5-13.6l-2.4 6h4.9l-2.5-6zM99.4 84.1v-7.4h-7.5v7.4h-4V65.7h4V73h7.5v-7.3h4v18.4h-4zM108 84.1V65.7h4v18.4h-4zM130.4 84.1l-1.7-4.1h-7.8l-1.7 4.1h-4.1l7.9-18.5h3.7l7.9 18.5h-4.2zm-5.6-13.6l-2.4 6h4.9l-2.5-6z' />
			</g>
			<path d='M36.8 31.5h5.6v2.8h-5.6z' />
		</svg>
	);
};
