import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { IonList, IonItem, IonLabel, IonItemDivider } from '@ionic/react';
import { T } from '../state';

export default function List({ onSelect, items }) {
	console.log('---items', items);
	const handleSelection = () => {
		if (onSelect) {
			onSelect();
		}
	};

	return (
		<IonList>
			<IonItemDivider mode='md' className='text-az text-xs uppercase mb-2 mt-16'>
				<IonLabel className='px-4'>{T('common.instructions.list')}</IonLabel>
			</IonItemDivider>
			{items?.map((item) => (
				<IonItem key={item.key} detail={false} lines='full'>
					<IonLabel>
						<ListItem label={item.label} />
					</IonLabel>
				</IonItem>
			))}
		</IonList>
	);
}

const ListItem = ({ label }) => {
	const [labelHilite, setLabelHilite] = useState(null);
	const [subLabel, setSubLabel] = useState(null);

	useEffect(() => {
		if (label.indexOf('/') > -1) {
			const tokens = label.split('/');
			setLabelHilite(tokens[0].trim());
			setSubLabel(tokens[1].trim());
		}
	}, [label]);

	return (
		<>
			{labelHilite && <span className='block font-rbt-bk text-az px-4'>{labelHilite}</span>}
			{subLabel && <span className='block font-rbt mt-1 mb-1 text-az px-4'>{subLabel}</span>}
			{!subLabel && !labelHilite && <span className='block font-rbt mt-1 mb-1 text-az px-4'>{label}</span>}
		</>
	);
};
