import { useState, useEffect, useRef } from 'react';
import { useGlobalState } from '../state';
import { AnimatePresence, motion } from 'framer-motion';

export default function Springboard() {
	// will start as visible, loading and them will recover files from cache
	const [loading] = useGlobalState('loading');
	const [index, setIndex] = useState(0);
	const indexRef = useRef(-1);
	const intervaleRef = useRef(null);

	useEffect(() => {
		intervaleRef.current = setInterval(() => {
			let next = indexRef.current + 1;
			if (next > 9) {
				next = 0;
			}
			indexRef.current = next;
			setIndex(next);
		}, 500);
		return () => clearInterval(intervaleRef.current);
	}, []);

	return (
		<>
			<AnimatePresence>
				{loading && (
					<>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.4 }}
							className='fixed left-0 top-0 w-screen h-full grid place-items-center'>
							<div className='fixed left-0 top-0 w-full h-full bg-white opacity-90' />
							<div className='relative w-48 h-48 rounded-full border-az border-8'>{instr[index]}</div>
						</motion.div>
					</>
				)}
			</AnimatePresence>
		</>
	);
}

const Instr_1 = () => (
	<svg viewBox='0 0 100 100' className='fill-current text-az'>
		<path d='M77.5 54.8c.3-.1.5-.4.5-.7V35.4c0-.3-.2-.6-.5-.7-.1 0-.2-.1-.3-.1-.2 0-.4.1-.5.2l-7.9 7.8-.1.1h-14v-3.9c0-1.1-.9-2-2-2s-2 .9-2 2v3.9h-4.2v-3.9c0-1.1-.9-2-2-2s-2 .9-2 2v3.9h-3.1v-3.9c0-1.1-.9-2-2-2s-2 .9-2 2v3.9h-8.9v-.4c0-1.1-.9-2-2-2s-2 .9-2 2V47c0 1.1.9 2 2 2s2-.9 2-2v-.4H30l-.3.6c-2.5 4.2-1.1 9.7 3.2 12.2.7.4 1.5.7 2.3.9l.3.1v2.9c0 1.1.9 2 2 2s2-.9 2-2v-2.7h3.1v2.7c0 1.1.9 2 2 2s2-.9 2-2v-2.7h4.3v2.7c0 1.1.9 2 2 2s2-.9 2-2v-2.7h2.7c4.9 0 8.9-4 8.9-8.9 0-1.6-.4-3.1-1.2-4.5l-.3-.6h3.8l.1.1 7.9 7.8c.1.1.3.2.6.2-.1.2 0 .1.1.1zm-41.8-7.1v8.7l-.6-.3c-2.4-1.4-3.2-4.4-1.9-6.8.4-.8 1.1-1.4 1.9-1.9l.6-.3v.6zm7-.6v9.7h-3.1V46.7h3.1v.4zm8.1 0v9.7h-4.2V46.7h4.2v.4zm7 9.7h-3.1V46.7H57.6c2.8 0 5 2.3 5 5 0 2.8-2.1 5-4.8 5.1z' />
	</svg>
);
const Instr_2 = () => (
	<svg className='fill-current text-az' version='1.1' id='Layer_1' x='0' y='0' viewBox='0 0 100 100'>
		<g id='Group_38' transform='translate(-905.912 -70.38)'>
			<path id='Rectangle_15' class='st0' d='M959.3 127.1h1.8V147h-1.8z' />
			<path id='Rectangle_16' class='st0' d='M950.7 127.1h1.8V147h-1.8z' />
			<path id='Rectangle_17' class='st0' d='M952.9 127.1h1.8V147h-1.8z' />
			<path id='Rectangle_18' class='st0' d='M957.1 127.1h1.8V147h-1.8z' />
			<path id='Rectangle_19' class='st0' d='M955.1 127.1h1.8V147h-1.8z' />
			<path
				id='Path_57'
				class='st0'
				d='M968.7 116.4l1.1.8 2.8-1.4-.7-2.4-1.5-2-2.8 1.4v1.4l.3.6-1.1.6-2.6-7.2 1.2-.6.3.6 1.1.8 2.8-1.4-.7-2.4-1.5-2-2.8 1.4v1.4l.3.6-1.1.5-2.6-7.3 1-.5.3.6 1.1.8 2.8-1.4-.7-2.4-1.5-2-2.8 1.4v1.4l.3.6-.8.4-1.6-4.6h-6.5l-1.6 4.6-.8-.4.3-.5v-1.4l-2.8-1.4-1.5 2-.7 2.4 2.8 1.4 1.1-.8.3-.6 1 .5-2.6 7.3-1.1-.5.3-.6v-1.4l-2.8-1.4-1.5 2-.7 2.4 2.8 1.4 1.1-.8.3-.6 1.2.6-2.6 7.2-1.1-.6.3-.6v-1.4l-2.8-1.4-1.5 2-.7 2.4 2.8 1.4 1.1-.8.3-.6 1.3.6-2 4.5 4 5h1.6v20.9h1.8V127h-.6v-1.3h12.3v1.3h-.6v19.9h1.8V126h1.6l4-5-1.6-4.6 1.3-.6.1.6zm-20.2 1.3l-.9.2-.9-.9c.4-.3.8-.6 1.1-1l1 .9-.3.8zm2.5-8.6l-.9.2-.9-.9c.4-.3.8-.6 1.1-1l1 .9-.3.8zm2.5-8.6l-.9.2-.9-.9c.4-.3.8-.6 1.1-1l1 .9-.3.8zm6.1 0l-.9.2-.9-.9c.4-.3.8-.6 1.1-1l1 .9-.3.8zm2.5 8.6l-.9.2-.9-.9c.4-.3.8-.6 1.1-1l1 .9-.3.8zm2.4 8.6l-.9.2-.9-.9c.4-.3.8-.6 1.1-1l1 .9-.3.8z'
			/>
		</g>
	</svg>
);
const Instr_3 = () => (
	<svg className='fill-current text-az' id='Layer_1' x='0' y='0' viewBox='0 0 100 100'>
		<g id='Group_37' transform='translate(-905.912 -70.38)'>
			<path
				id='Path_53'
				class='st0'
				d='M970.7 105.5c2.1-.1 3.8-1.9 3.7-4.1v-.1c-.1-.8-.7-1.5-1.6-1.6-2.1-.2-4 1.4-4.2 3.6v.1c0 .4-.2.7-.5 1l-11.7 11.2 2 1.9 11.2-11.7c.4-.1.8-.3 1.1-.3z'
			/>
			<path id='Path_54' class='st0' d='M953.3 118.8l2.1 2.1-18.7 19.5c-.8.8-2 .8-2.8.1-.8-.8-.8-2-.1-2.8l.1-.1 19.4-18.8z' />
			<path
				id='Path_55'
				class='st0'
				d='M943.2 103.4c-.1-2.1-1.9-3.8-4.1-3.7h-.1c-.8.1-1.5.7-1.6 1.6-.2 2.1 1.4 4 3.6 4.2h.1c.4 0 .7.2 1 .5l33.1 34.4c.8.8 2 .8 2.8 0 .8-.8.8-2 0-2.8h-.1l-34.4-33.1c-.2-.3-.3-.7-.3-1.1z'
			/>
		</g>
	</svg>
);
const Instr_4 = () => (
	<svg className='fill-current text-az' viewBox='0 0 100 100'>
		<path d='M70.788 64.12v-16.6-.1-.3c0-5.3-7.6-9.5-17.2-9.5s-17.2 4.2-17.2 9.5v17c0 .3.1.7.2 1h-.2c0 5.3 7.6 9.5 17.2 9.5s17.2-4.1 17.2-9.4h-.2c.1-.4.2-.8.2-1.1zm-25.8-8.8c1.1.4 2.3.6 3.4.8v14.6c-1.2-.2-2.3-.5-3.4-.9v-14.5zm13.8.8c1.2-.2 2.3-.5 3.4-.8v14.5c-1.1.4-2.3.7-3.4.9v-14.6zm-5.2-15.1c8.1 0 13.8 3.2 13.8 6s-5.7 6-13.8 6-13.8-3.2-13.8-6 5.7-6 13.8-6zm-6.7-8.2l-15.2 4.8h-.5c-1 .1-1.8-.5-2-1.5-.1-1 .5-1.8 1.5-2l14.8-4.5c0-2.3 1.9-4.2 4.2-4.2 2.3 0 4.2 1.9 4.2 4.2s-1.9 4.2-4.2 4.2c-1.1.1-2-.3-2.8-1z' />
	</svg>
);
const Instr_5 = () => (
	<svg className='fill-current text-az' viewBox='0 0 100 100'>
		<path d='M47.488 30.22h5.4l1-1.4v-6.5c0-.3-.3-.6-.6-.5h-6.1c-.3 0-.6.2-.6.5v6.5l.9 1.4zm-.9 12.7c-3.4.7-8.1 3-8.1 6.9 0 4.3 5.7 4-.8 12-7.5 9.1 3.3 16.4 12.3 16.4 9.1 0 19.8-7.3 12.4-16.4-6.5-8-.8-7.7-.8-12 0-3.9-4.6-6.2-8-6.9v11.7c1.6 2 1.3 4.9-.6 6.5s-4.9 1.3-6.5-.6c-1.5-1.8-1.4-4.4.2-6.1l-.1-11.5zm5.9 10.8v-22.5h-4.9v22.4c1.6-.8 3.4-.8 4.9.1zm-2.5.4c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.5 3.6c2 0 3.6-1.5 3.7-3.5 0-2-1.5-3.6-3.5-3.7h-.1zm5.9 12.9v3.3h-11.8v-3.3h11.8zm-1 1.1h-9.7v1.2h9.7v-1.2z' />
	</svg>
);
const Instr_6 = () => (
	<svg className='fill-current text-az' viewBox='0 0 100 100'>
		<path d='M75.5 54.4c0-3.4-2.7-6.1-6.1-6.2-.4-1.1-.8-2.1-1.3-3.1 2.4-2.5 2.3-6.5-.2-8.8-2.4-2.3-6.2-2.3-8.6 0-1-.5-2-1-3.1-1.3-.1-3.4-2.9-6.2-6.4-6.1-3.3.1-6 2.8-6.1 6.1-1.1.4-2.1.8-3.1 1.3-2.5-2.3-6.3-2.3-8.7.1-2.3 2.5-2.3 6.3 0 8.7-.5 1-1 2-1.3 3.1-3.4.1-6.2 3-6.1 6.4.1 3.3 2.8 6 6.1 6.1 1.3 4.1 3.9 7.7 7.4 10.3.5.4 1.2.3 1.6-.2l4.2-5.5h12.5l4.2 5.5c.2.3.6.4.9.5.2 0 .5-.1.7-.2 3.5-2.5 6.1-6.1 7.4-10.3 3.3-.3 6-3 6-6.4zm-7.4 0c0 1.2-.1 2.5-.4 3.7-2-.9-3-3.2-2.1-5.2.4-1 1.2-1.7 2.1-2.1.3 1.1.4 2.4.4 3.6zM60 39.2c2 1.4 3.8 3.1 5.2 5.2-1.5.6-3.2.3-4.3-.8-1.2-1.2-1.6-2.9-.9-4.4zm3.6-2.4c2.2 0 3.9 1.7 3.9 3.9 0 .8-.2 1.6-.7 2.3-1.5-2.2-3.3-4-5.5-5.5.7-.4 1.5-.7 2.3-.7zM50 31.2c1.9 0 3.5 1.4 3.9 3.2-1.3-.2-2.6-.4-3.9-.4-1.3 0-2.6.1-3.9.4.4-1.8 2-3.2 3.9-3.2zm3.7 5.4c-.6 1.5-2.1 2.4-3.7 2.4s-3-1-3.7-2.4c1.2-.2 2.4-.4 3.7-.4 1.2.1 2.5.2 3.7.4zm-14.5 7c-1.1 1.2-2.8 1.5-4.3.8 1.4-2 3.1-3.8 5.2-5.2.6 1.6.2 3.3-.9 4.4zM33.6 38c1.3-1.3 3.4-1.5 5-.5-2.2 1.5-4 3.3-5.5 5.5-1.1-1.6-.9-3.7.5-5zm-1.7 16.4c0-1.2.1-2.5.4-3.7 1.5.6 2.4 2.1 2.4 3.7s-1 3-2.4 3.7c-.3-1.2-.4-2.4-.4-3.7zm-5.1 0c0-1.9 1.4-3.5 3.2-3.9-.3 1.3-.4 2.6-.4 3.9 0 1.3.1 2.6.4 3.9-1.9-.3-3.2-2-3.2-3.9zm34.8 14l-3.9-5c-.2-.3-.6-.4-.9-.5H43.2c-.4 0-.7.2-.9.5l-3.9 5c-2.6-2.1-4.5-4.9-5.6-8.1 3.2-1.1 4.9-4.7 3.8-7.9-.6-1.8-2-3.2-3.8-3.8.3-.7.6-1.5.9-2.2.8.4 1.7.6 2.7.6 1.7 0 3.2-.7 4.4-1.8 1.9-1.9 2.4-4.7 1.2-7.1.7-.3 1.4-.6 2.2-.9.9 2.5 3.2 4.1 5.8 4.1 2.6 0 5-1.7 5.8-4.1.7.3 1.5.6 2.2.9-1.5 3.1-.2 6.8 2.9 8.3 1.7.8 3.7.8 5.4 0 .3.7.6 1.4.9 2.2-2.6.8-4.3 3.2-4.2 5.8 0 2.6 1.6 5 4.1 5.8-1 3.2-3 6-5.5 8.2zM70 58.3c.3-1.3.4-2.6.4-3.9 0-1.3-.1-2.6-.4-3.9 1.9.4 3.2 2 3.2 3.9s-1.3 3.5-3.2 3.9z' />
	</svg>
);
const Instr_7 = () => (
	<svg className='fill-current text-az' viewBox='0 0 100 100'>
		<path d='M66.1 51.8l5.7-17.6c.3-.4.5-.9.5-1.4v-.1-.3c-.2-1-1-1.9-2.5-2.8-1.1-3.5-13.2-4.4-19.7-4.4s-18.6.9-19.7 4.4c-1.5.8-2.4 1.7-2.5 2.8v.4c0 .5.2 1 .5 1.4l5.7 17.6c1.1 3.4 3.5 6.3 6.6 8L37 71.3c-.1.4 0 .8.4 1 .5.3 1 .5 1.5.7 7.3 2.3 15.2 2.3 22.5 0 .5-.2 1-.4 1.5-.7.4-.2.5-.6.4-1l-3.7-11.5c3.1-1.7 5.4-4.5 6.5-8zm2.4-12.6l-2-2c1-.3 2-.7 3-1.2l-1 3.2zm-1.3 1.1c-.9.3-1.8.6-2.7.8l.8-2.7 1.9 1.9zM50 26.9c13.4 0 18.2 2.6 18.2 3.3s-4.7 3.3-18.2 3.3-18.2-2.6-18.2-3.3 4.8-3.3 18.2-3.3zm-20.6 5.9c0-.4.5-1 1.3-1.5 2.3 3 13.3 3.8 19.4 3.8s17.1-.8 19.4-3.8c.8.5 1.3 1 1.3 1.5 0 2-7.9 4.8-20.7 4.8s-20.8-2.8-20.7-4.8zm32 5.6c.8-.1 1.5-.3 2.2-.4l-.7 2.6-1.5-2.2zm.2 3.3c-.9.1-1.8.3-2.7.4l1.2-2.7 1.5 2.3zm-3.1-2.9l-1.1 2.6-1.2-2.4c.8 0 1.5-.1 2.3-.2zM56 42.3c-.7.1-1.4.1-2.1.1l1.1-2.2 1 2.1zm-2.3-3.1l-1.2 2.4-1.1-2.3c.7-.1 1.5-.1 2.3-.1zm-2.6 3.4h-2.2l1.1-2.3 1.1 2.3zm-2.4-3.4l-1.1 2.3-1.2-2.4c.7.1 1.5.1 2.3.1zm-2.6 3.3c-.7 0-1.4-.1-2.1-.1l1-2.1 1.1 2.2zM43.8 39l-1.2 2.4-1.1-2.6c.8.1 1.5.2 2.3.2zm-3.9.3l1.2 2.7c-.9-.1-1.8-.2-2.7-.4l1.5-2.3zm-2.8 1.3l-.8-2.6c.7.1 1.5.3 2.2.4l-1.4 2.2zm-1.6.5c-.9-.2-1.8-.5-2.7-.8l1.9-1.9.8 2.7zm-5-5c1 .5 1.9.9 3 1.2l-2 2-1-3.2zm30.9 35.1c-.2.1-.5.2-.7.3-6.9 2.2-14.4 2.2-21.3 0-.3-.1-.5-.2-.7-.3l.9-2.9c6.9 2 14.1 2 21 0l.8 2.9zm-1.9-5.9l.5 1.4c-6.5 1.9-13.4 1.9-20 0l.5-1.4c6.2 1.6 12.8 1.6 19 0zM41 63.8l1-3.2c1.7.7 3.4 1 5.2 1h5.6c1.8 0 3.6-.3 5.2-1l1 3.2c-5.9 1.4-12.1 1.4-18 0zm11.8-3.9h-5.6c-5.4 0-10.1-3.5-11.8-8.6l-3.1-9.5c4.8 1.5 9.8 2.3 14.9 2.3.2.1.3.1.5 0h5.1c5.1-.1 10.1-.8 14.9-2.3l-3.1 9.5c-1.7 5.2-6.4 8.6-11.8 8.6z' />
	</svg>
);
const Instr_8 = () => (
	<svg className='fill-current text-az' viewBox='0 0 100 100'>
		<path d='M29.1 70.9c1.1 1.1 3 1.1 4.1 0L46.1 58c.2 2 2 3.4 4 3.2 2-.2 3.4-2 3.2-4-.2-1.7-1.5-3-3.2-3.2l2.2-2.2c.2 2 2 3.4 4 3.2 2-.2 3.4-2 3.2-4-.2-1.7-1.5-3-3.2-3.2l2.2-2.2c.2 2 2 3.4 4 3.2 2-.2 3.4-2 3.2-4-.2-1.7-1.5-3-3.2-3.2l2.2-2.2c.2 2 2 3.4 4 3.2 2-.2 3.4-2 3.2-4-.2-1.7-1.5-3-3.2-3.2l2.3-2.3c1.1-1.1 1.1-3 0-4.1-1.1-1.1-3-1.1-4.1 0l-2.3 2.3c-.2-2-2-3.4-4-3.3-2 .2-3.4 2-3.3 4 .2 1.7 1.5 3.1 3.3 3.3l-2.2 2.2c-.2-2-2-3.5-4-3.3-2 .2-3.5 2-3.3 4 .2 1.7 1.5 3.1 3.3 3.3l-2.2 2.2c-.2-2-2-3.5-4-3.3-2 .2-3.5 2-3.3 4 .2 1.7 1.5 3.1 3.3 3.3L46 49.9c-.2-2-2-3.5-4-3.3-2 .2-3.5 2-3.3 4 .2 1.7 1.5 3.1 3.3 3.3L29.1 66.8c-1.2 1.1-1.2 3 0 4.1zm22.1-14.8c.7.6.8 1.6.4 2.5l-.9-.9c-.3-.3-.7-.3-1 0s-.3.7 0 1l.9.9c-.8.4-1.8.2-2.5-.4-.9-.9-.9-2.2 0-3.1s2.2-.9 3.1 0zm6.2-6.2c.7.6.8 1.6.4 2.5l-.9-.9c-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1l.9.9c-.8.4-1.8.2-2.5-.4-.9-.9-.9-2.2 0-3.1s2.2-.8 3.1 0zm6.1-6.1c.6.7.8 1.6.4 2.5l-.9-1c-.3-.3-.7-.3-1 0s-.3.7 0 1l.9.9c-1.1.5-2.4.1-2.9-1-.4-.8-.2-1.8.4-2.5.9-.7 2.3-.7 3.1.1zm6.2-6.2c.6.7.8 1.6.4 2.5l-.9-.9c-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1l.9.9c-1.1.5-2.4.1-2.9-1s-.1-2.4 1-2.9c.8-.4 1.8-.2 2.5.4zm-10.4-4.2c-.7-.6-.8-1.6-.4-2.5l.9.9c.3.3.7.3 1 0 .3-.3.3-.7 0-1l-.9-.9c.8-.4 1.8-.2 2.5.4.8.9.8 2.2-.1 3.1-.8.8-2.2.8-3 0zm-6.2 6.1c-.7-.6-.8-1.6-.4-2.5l.9.9c.3.3.7.3 1 0s.3-.7 0-1l-.9-.9c1.1-.5 2.4-.1 2.9 1 .4.8.2 1.8-.4 2.5-.9.9-2.2.9-3.1 0zM47 45.7c-.7-.6-.8-1.6-.4-2.5l.9.9c.3.3.7.3 1 0s.3-.7 0-1l-.9-.9c1.1-.5 2.4-.1 2.9 1 .4.8.2 1.8-.4 2.5-.9.8-2.3.8-3.1 0zm-6.2 6.2c-.7-.6-.8-1.6-.4-2.5l.9.9c.3.3.7.3 1 0s.3-.7 0-1l-.9-.9c1.1-.5 2.4-.1 2.9 1 .4.8.2 1.8-.4 2.5-.9.8-2.2.8-3.1 0zm-10.7 16l37.7-37.7c.6-.6 1.5-.6 2.1 0s.6 1.5 0 2.1L32.1 69.9c-.6.5-1.5.5-2.1 0-.5-.6-.5-1.5.1-2z' />
	</svg>
);
const Instr_9 = () => (
	<svg className='fill-current text-az' version='1.1' id='Layer_1' x='0' y='0' viewBox='0 0 100 100'>
		<g id='Group_56' transform='translate(-905.912 -70.38)'>
			<path id='Path_132' class='st0' d='M972.8 103.4c-5.3-5.2-13.7-5.2-19 0l-.1.1c5.3 7.3 11.7 13.7 19 19l.1-.1c5.3-5.2 5.3-13.7 0-19z' />
			<path
				id='Path_133'
				class='st0'
				d='M952.9 104.5c-.8 1-1.5 2.1-2 3.3 4.7 6.9 10.6 12.8 17.5 17.5 1.2-.5 2.4-1.2 3.4-2-7.3-5.1-13.7-11.5-18.9-18.8z'
			/>
			<path id='Path_134' class='st0' d='M950.4 109.4c-1.9 7.1 2.3 14.5 9.4 16.4 2.3.6 4.7.6 7 0-6.3-4.4-11.9-10-16.4-16.4z' />
			<path
				id='Path_135'
				class='st0'
				d='M951.2 121.4l-1-1.6c-.2.6-.3 1.2-.3 1.9l-14 14c-1.2 1.3-1.2 3.4.1 4.6 1.3 1.2 3.3 1.2 4.5 0l14-14c.6 0 1.3-.1 1.9-.3-.6-.3-1.1-.6-1.6-1-1.9.1-3.5-1.3-3.7-3.3.1-.1.1-.2.1-.3z'
			/>
		</g>
	</svg>
);
const Instr_10 = () => (
	<svg className='fill-current text-az' viewBox='0 0 100 100'>
		<path d='M79.5 54.7c-.4-3.3-1.4-6.5-3-9.5-1.3-2.7-3.1-5.1-5.2-7.2-2-2-4-3-5.6-3-.6 0-1.2.1-1.7.4-1.7.9-2.6 3.2-2.3 6.4 0 .4.1.9.2 1.3-.2.8-.4 1.7-.7 2.6-1 3.1-2.4 6.1-4.2 8.8-.3.5-.6 1-1 1.5.1-.8.2-1.6.2-2.4 0-9.5-7.7-17.2-17.2-17.2-4.6 0-8.9 1.8-12.2 5-1 1-1.9 2.1-2.6 3.3V31.6c.7 0 1.2-.6 1.2-1.2 0-.7-.6-1.2-1.2-1.2h-2.5c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2v22.1c0 9.5 7.7 17.2 17.2 17.2 3.9 0 7.7-1.3 10.8-3.8 5.2-3.2 12.6-7 18.8-7.2 1.2-.1 2.3.1 3.5.3 1 .8 2.3 1.2 3.6 1.3.6 0 1.2-.1 1.7-.4 1.6-.9 2.4-3.2 2.2-6.4zm-48.1 4.8c-.8-.4-1.2-1.2-1.1-2.1V41.7c2.2-1.6 4.7-2.5 7.4-2.7-3.5.6-6.1 3.7-6.1 7.3v13.2h-.2zm12.4-12h-3.7v-6c2.2.6 3.7 2.5 3.7 4.8v1.2zm9.4 9.9h-6.9v-2.5h7.3c0 .9-.2 1.7-.4 2.5zm-13 0v-2.5h3.7v2.5h-3.7zm3.6 2.4V61c0 2.2-1.5 4.2-3.7 4.8v-6h3.7zM34 50h3.7v2.5H34V50zm0-2.5v-1.3c0-2.2 1.5-4.2 3.7-4.8v6l-3.7.1zm0 7.4h3.7v2.5H34v-2.5zm19.6-2.4h-7.3V50h6.9c.2.8.4 1.6.4 2.5zm-9.8 0h-3.7V50h3.7v2.5zM34 59.8h3.7v6c-2.2-.6-3.7-2.5-3.7-4.8v-1.2zm6.2 8.5c3.5-.6 6.1-3.7 6.1-7.3v-1.2h6c-.5 1.1-1.2 2.2-2 3.1L48.2 65c-2.3 2-5.1 3.1-8 3.3zm12.1-20.8h-6v-1.3c0-3.6-2.6-6.7-6.1-7.3 5.3.5 9.9 3.8 12.1 8.6zm-28.1 6.2c0-3.6 1.3-7.1 3.7-9.8v4.8c.7 1.4.1 3-1.2 3.7.7 0 1.2.6 1.2 1.2 0 .7-.6 1.2-1.2 1.2 0 .7.5 1.2 1.2 1.2v1.2c-.1 1.8.9 3.5 2.5 4.3.4.2.8.4 1.2.5.5 3.2 2.9 5.7 6.1 6.2v.1c-7.7-.6-13.5-6.9-13.5-14.6zM60.6 59c-1.9.6-3.7 1.4-5.4 2.3 1.4-1.7 2.7-3.6 3.9-5.4 1.6-2.6 3-5.3 4-8.2.5 1.2 1 2.5 1.6 3.6 1.1 2.2 2.5 4.3 4.1 6.1-2.8.1-5.6.6-8.2 1.6zm15.5-.1c-.2.1-.4.1-.6.1-.5 0-1.8-.2-3.9-2.3-1.9-1.9-3.5-4.1-4.7-6.6-1.4-2.7-2.4-5.6-2.8-8.6-.2-2.1.2-3.6.9-4 .2-.1.4-.1.6-.1.5 0 1.8.2 3.9 2.3 1.9 1.9 3.5 4.1 4.7 6.6 1.4 2.7 2.4 5.6 2.8 8.6.2 2.1-.2 3.6-.9 4z' />
	</svg>
);

const instr = [<Instr_1 />, <Instr_2 />, <Instr_3 />, <Instr_4 />, <Instr_5 />, <Instr_6 />, <Instr_7 />, <Instr_8 />, <Instr_9 />, <Instr_10 />];
