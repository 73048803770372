import { useEffect } from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import get from 'lodash/get';
import merge from 'lodash/merge';
import capitalize from 'lodash/capitalize';
import axios from 'axios';

import structure from '../assets/data/structure.json';
import springboard from '../assets/data/springboard.json';
import cabines from '../assets/data/cabines.json';
import totens from '../assets/data/totens.json';
import karaoke from '../assets/data/karaoke.json';
import educacao from '../assets/data/educacao.json';
import midiateca from '../assets/data/midiateca.json';
import depoimentos from '../assets/data/depoimentos.json';
import raptrap from '../assets/data/raptrap.json';
import mixer from '../assets/data/mixer.json';
import zum from '../assets/data/zum.json';
import novamusica from '../assets/data/nova-musica.json';
import quiz from '../assets/data/quiz.json';

const { setGlobalState, getGlobalState, useGlobalState } = createGlobalState({
	loading: false, // defaults to true
	locale: `pt`,
	structure: null,
	history: null,
	currLvl: 1,
	currIa: null,
	currPts: null,
	token: null,
	paired: null,
	T: null,
});

const mock = (timeout) => new Promise((r) => setTimeout(r, timeout * 1000));

// main structure model resolution, as well first view recovery, in case of closing it in the middle of session
// and all sort to entrance and persistence resolution
let resolved = false;

// resolve just a single time
export const resolve = async ({ history }) => {
	if (!resolved) {
		// executar esse bloco apenas uma vez
		resolved = true;

		// lembrar de remover
		clear();

		// setGlobalState('loading', true);
		setGlobalState('structure', structure);
		setGlobalState('history', history);

		// valores que foram recuperados
		const retrieved = retrieve();

		// o que sobrou de sessões passadas
		console.log('retrieved', retrieved);

		if (retrieved) {
			// validate token duration
			const { state } = retrieved;

			// validate state
			if (state) {
				setGlobalState('locale', state?.indexOf('/pt/') > -1 ? 'pt' : 'en');
				setTimeout(() => history.push(state), 100);
			} else {
				setTimeout(() => history.push(`/ln`), 100);
			}

			setTimeout(() => {
				setGlobalState('loading', false);
			}, 1000);
		} else {
			// works
			setTimeout(() => history.push(`/ln`), 100);
			// setTimeout(() => history.push(`/pt/i/c2Smpnm/13/q/s?c=TO1_T1_&p=1`), 100);
		}

		// return `/en/s`;
		// return `/en/i/lNgGuzO/o/`;
		// return `/en/i/lNgGuzO/f/`;
		// ux { o = options, f = searchable, k = karaoke,  }
		// state playing|paused
	}
};

// translation hook
export const T = (key) => {
	let result = key;

	const locale = getGlobalState('locale');
	const structure = getGlobalState('structure');

	if (structure) {
		const value = get(structure.localized[locale], key);

		if (value) {
			result = value;
		}
	}

	return result;
};

// init visit
export const sign_in = async ({ name, email, birthday, device }) => {
	// raise the loading ui
	setGlobalState('loading', true);

	// init error variable
	let error = null;

	try {
		// sign in
		const pts = 0;
		const payload = { name, email, birthday, device, pts };
		const rs = await axios.post(`${process.env.REACT_APP_API}/visit`, { ...payload });

		if (rs.status === 201 && rs.data) {
			const { token } = rs.data;
			store({ token });
			setGlobalState('token', token);
		}
	} catch (e) {
		// capture error
		error = e;
	} finally {
		// release the loading ui
		setGlobalState('loading', false);
	}

	return { error };
};

// pair with ia
export const pair = async ({ ia_uid, instance, otp }) => {
	// define o resultado
	let result = { error: null };
	// loading
	setGlobalState('loading', true);
	//
	try {
		// retrieve saved data
		const session = retrieve();
		// get ia instance uid
		const ia = getIaModelOptions({ ia: ia_uid, instance });
		const instance_uid = ia.instance_id;

		// session not null
		if (session) {
			// deconstruct session
			const { name, email, locale, birthday, device, token } = session;
			// otp
			if (otp) {
				const payload = { name, email, locale, device, birthday, otp };

				const address = `${process.env.REACT_APP_API}/pair/${instance_uid}`;
				const options = { headers: { authorization: `bearer ${token}` } };
				console.log(address, payload, options);
				const rs = await axios.post(address, { ...payload }, options);

				if (rs.status === 204) {
					setGlobalState('paired', true);
				}
			}
		}
	} catch (error) {
		console.log('.....error', error);
		if (error?.response.status === 400) {
			result.error = 'bad-request';
		}

		if (error?.response.status === 401) {
			result.error = 'not-match';
		}

		if (error?.response.status === 423) {
			result.error = 'locked';
		}

		// try to recover or error message
		console.log('error', error);
	} finally {
		setGlobalState('loading', false);
	}

	return result;
};

export const unpair = ({ ia, instance }) => {
	const is_paired = getGlobalState('paired');

	// if not paired don't bother
	if (!is_paired) {
		return;
	}

	// raise loading flag
	setGlobalState('loading', true);

	// will unpair, liberating ui
	try {
		const _ia = getIaModelOptions({ ia, instance });

		const instance_uid = _ia.instance_id;
		const token = getGlobalState('token');
		const address = `${process.env.REACT_APP_API}/pair/${instance_uid}`;
		const options = { headers: { authorization: `bearer ${token}` } };
		const rs = axios.delete(address, options);

		if (rs.status === 204) {
			setGlobalState('paired', false);
		}

		console.log(rs.status, rs.data);
	} catch (error) {
		if (error?.response.state === 404) {
			setGlobalState('paired', false);
		}
		console.log('error', error);
		// maybe the pair did not exist yet
	} finally {
		// release loading flag
		setGlobalState('loading', false);
	}
};

export const set_lungo_state = async ({ i_state, v_state, ia, instance, observe, ref = null }) => {
	setGlobalState('loading', true);

	try {
		const payload = { p: {} };

		if (i_state) {
			payload.p.i_state = i_state;
		}

		if (v_state) {
			payload.p.v_state = v_state;
		}

		const model = getIaModelOptions({ ia, instance });
		console.log(ia, instance, model);
		const instance_uid = model.instance_id;

		const token = getGlobalState('token');

		const address = `${process.env.REACT_APP_API}/ia/${instance_uid}`;
		const options = { headers: { authorization: `bearer ${token}` } };
		const rs = await axios.put(address, { payload }, options);

		if (rs.status === 204) {
			if (observe) {
				let _ref = null;
				if (ref) {
					_ref = ref;
				} else {
					_ref = v_state;
				}
				ia_observe({ ia, instance, ref: _ref });
			}
		}
	} catch (error) {
		console.log('error', error);
	} finally {
		setGlobalState('loading', false);
	}
};

// get the springboard model data

export const getSpringBoardModel = () => {
	return springboard;
};

// get per ia model, searching in structure
export const getIaModel = ({ ia }) => {
	// deveria ver de um json
	let model = null;

	// find the correct model
	springboard.find((item) => {
		// achar o model pelo springboard
		const found = item.ias.find((e) => e.id === ia);
		// completar o model
		// console.log(found, ia);
		if (found) {
			model = found;
		}
	});

	return model;
};

export const getNovaMusicaList = () => {
	return novamusica;
};

export const getQuizModel = ({ code }) => {
	const found = quiz.find((e) => e.code === code);
	return found;
};

export const hasNextQuiz = ({ code, next }) => {
	const target = `${code}P${next}`;
	const found = quiz.find((e) => e.code === target);

	return found !== null && found !== undefined;
};

// entrar na sala
export const getIaUx = ({ ia, instance }) => {
	let ux = `o`;
	const model = getIaModelOptions({ ia, instance });
	if (model) {
		ux = model.ux;
	}
	return ux;
};

export const getIaModelOptions = ({ ia, instance }) => {
	// deveria ver de um json
	let model = null;

	// find the correct model
	// totens
	totens.find((item) => {
		let found = null;

		if (item.id === ia && item.instance === instance) {
			found = item;
		}

		if (found) {
			model = found;
		}
	});

	// cabines
	cabines.find((item) => {
		let found = null;

		if (item.id === ia && item.instance === instance) {
			found = item;
		}

		if (found) {
			model = found;
		}
	});

	karaoke.find((item) => {
		let found = null;
		if (item.id === ia && item.instance === instance) {
			found = item;
		}

		if (found) {
			model = found;
		}
	});

	raptrap.find((item) => {
		let found = null;

		if (item.id === ia && item.instance === instance) {
			found = item;
		}

		if (found) {
			// const bases = times(10, (e) => ({ key: `QLu6hnH-${e + 1 + 71}.mp4`, label: `Base ${e + 1} ${e < 4 ? `Vandal` : ``}`, filter: `` }));
			model = found;
			// model.options = [...model.options, ...bases];
		}
	});

	midiateca.find((item) => {
		let found = null;

		if (item.id === ia && item.instance === instance) {
			found = item;
		}

		if (found) {
			model = { ...found };
			// adjust musica e educação
			model.groups[2].options = model.groups[2].options.map((e) => {
				let result = null;
				if (e.key.indexOf(`x8DgDdd`) > -1) {
					result = { ...e, key: e.key.replace(`x8DgDdd`, `drxwiWY`) };
				} else {
					result = { ...e };
				}

				return result;
			});

			let _depoimentos = [...depoimentos];
			// adjust depoimentos
			_depoimentos = _depoimentos.map((e) => {
				let item = null;
				if (model.instance_id === `KU1EfYU`) {
					item = { key: `3kY31ll-${e.id}`, label: capitalize(e.label) };
				} else {
					item = { key: `${model.instance_id}-${e.id}`, label: capitalize(e.label) };
				}
				return item;
			});

			model.groups[3] = { filter: 'depoimentos', label: { pt: 'Depoimentos', en: 'Testimonials' }, options: [..._depoimentos] };
		}
	});

	educacao.find((item) => {
		let found = null;

		if (item.id === ia && item.instance === instance) {
			found = item;
		}

		if (found) {
			model = found;
		}
	});

	mixer.find((item) => {
		let found = null;

		if (item.id === ia && item.instance === instance) {
			found = item;
		}

		if (found) {
			model = found;
		}
	});

	zum.find((item) => {
		let found = null;

		if (item.id === ia && item.instance === instance) {
			found = item;
		}

		if (found) {
			model = found;
		}
	});

	// historia da percussao?
	// zumzum?
	// raptrap?
	// mixagem?

	return model;
};

export const store = (data) => {
	try {
		// init data retreive
		let _data = null;
		// recover current
		const current = retrieve();
		// if we've got the current, merge with data
		_data = merge(current || {}, data);
		// _data it's not null
		if (_data) {
			// save it on store
			window.localStorage.setItem('state', JSON.stringify(_data));
		}
	} catch (error) {
		console.log('storage error', error);
	}
};

export const clear = () => {
	window.localStorage.clear();
};

export const retrieve = () => {
	let retrieved = null;
	// stored
	const stored = window.localStorage.getItem('state');

	if (stored) {
		try {
			retrieved = JSON.parse(stored);
		} catch (error) {
			console.log(error);
		}
	}

	return retrieved;
};

// reference to compare curr/last state
let __ref = '';

// poll is a reference to keep looking or not
let poll = null;

export const ia_observe = async ({ ia, instance, ref }) => {
	poll = true;

	try {
		__ref = ref;

		const model = getIaModelOptions({ ia, instance });
		const instance_uid = model.instance_id;

		const token = getGlobalState('token');

		const address = `${process.env.REACT_APP_API}/ia/${instance_uid}`;
		const options = { headers: { authorization: `bearer ${token}` } };
		const rs = await axios.get(address, options);
		console.log(__ref, rs.data, rs.status);
		if (rs.status === 200) {
			// temos um pair?
			if (rs.data && rs.data.p && rs.data.p.ts) {
				// extract the v_state
				const { v_state } = rs.data.p;

				if (__ref !== v_state) {
					__ref = '';

					const H = getGlobalState('history');

					H.push({
						pathname: v_state,
						search: '',
					});

					poll = false;
				}
			}
		}

		// observe for changes, and navigate accordingly
	} catch (error) {
		console.log('error', error);
	} finally {
		if (poll) {
			setTimeout(() => ia_observe({ ia, instance, ref }), 2000);
		}
	}
};

let __v_state = null;
let __v_state_poll = null;

export const ia_observe_v_state = async ({ ia, instance, v_state, callback }) => {
	__v_state_poll = true;

	try {
		__v_state = v_state;

		const model = getIaModelOptions({ ia, instance });
		const instance_uid = model.instance_id;

		const token = getGlobalState('token');

		const address = `${process.env.REACT_APP_API}/ia/${instance_uid}`;
		const options = { headers: { authorization: `bearer ${token}` } };
		const rs = await axios.get(address, options);

		if (rs.status === 200) {
			// temos um pair?
			if (rs.data && rs.data.p && rs.data.p.ts) {
				// extract the v_state
				const { v_state } = rs.data.p;

				if (__v_state === v_state) {
					__v_state = '';

					const H = getGlobalState('history');

					H.push({
						pathname: v_state,
						search: '',
					});

					callback();

					__v_state_poll = false;
				}
			}
		}

		// observe for changes, and navigate accordingly
	} catch (error) {
		console.log('error', error);
	} finally {
		if (__v_state_poll) {
			setTimeout(() => ia_observe_v_state({ ia, instance, v_state, callback }), 2000);
		}
	}
};

export const ia_check_reset = async ({ ia, instance, locale }) => {
	let result = false;
	try {
		const model = getIaModelOptions({ ia, instance });
		const instance_uid = model.instance_id;

		const token = getGlobalState('token');

		const address = `${process.env.REACT_APP_API}/ia/${instance_uid}`;
		const options = { headers: { authorization: `bearer ${token}` } };
		const rs = await axios.get(address, options);

		if (rs.status === 200) {
			if (rs.data && rs.data.p && rs.data.p.ts) {
				if (rs.data.p.v_state === `/${locale}/s`) {
					result = true;
				}
			} else {
				result = true;
			}
		}
	} catch (error) {
		console.log('error', error);
	} finally {
		return result;
	}
};

// ignore? talvez não seja necessario
export const ia_ignore = () => {
	poll = false;
};

// the main global state component to hook the structure etc...
export const GlobalState = ({ history, location }) => {
	useEffect(() => {
		(async () => {
			setGlobalState('history', history);
			const resolved = await resolve();
			if (resolved !== location.pathname) {
				history.push(resolved);
			}
		})();
	}, []);

	return null;
};

export { useGlobalState };
