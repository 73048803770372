import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

export default ({ onTap, className, children }) => {
	return (
		<div className={`w-screen ${className}`}>
			<motion.div onTap={onTap} className={`pill`}>
				{children}
			</motion.div>
		</div>
	);
};
