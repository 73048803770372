import { IonContent, IonPage } from '@ionic/react';
import { useParams, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Marca } from '../components';

export default function PickLanguage() {
	// carousel of greetings
	return (
		<IonPage>
			<IonContent fullscreen>
				<Marca className='fixed w-32 left-10 top-4 z-10 fill-current text-az' />
				{/* <div
					className='fixed left-8 top-4 w-screen h-screen '
					style={{ background: `url(/assets/language/bkg.jpg) no-repeat center center`, backgroundSize: `cover` }}
				/> */}
				<div className='fixed left-0 top-0 w-screen flex flex-col justify-items-center items-start mt-32'>
					<h2 className='ml-6 mt-24 text-4xl font-rbt-lt'>
						Escolha <br />
						sua língua
					</h2>
					<div className='w-10 h-20'></div>
					<div className='fixed bottom-10'>
						<Link to='/pt/u' className='pill ml-4'>
							Português
						</Link>
						<div className='w-10 h-4'></div>
						<Link to='/en/u' className='pill ml-4'>
							English
						</Link>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
}
